import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import RatingComponent from "../../components/RatingComponent";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { BiHappyAlt, BiSad } from "react-icons/bi";
import { BsEmojiNeutral } from "react-icons/bs";
import CustomButton from "../CustomButton";
import { async } from "q";
import axios from "axios";

function RatingModal({
  show,
  handleClose,
  rating,
  setRating,
  feedback,
  setFeedback,
  // handleRatingSubmit,
  handleSubmit,
  isLoading,
  // cancelRatingHandler,
  // isSubmitLoading
}) {
  // const [rating, setRating] = useState(3);
  // const [feedback, setFeedback] = useState("");
  const [emotion, setEmotion] = useState({
    title: "neutral",
    icon: <BsEmojiNeutral size="24px" color="grey" />,
  });

  console.log(isLoading, ">>>");

  const handleRatingChange = async (value) => {
    setRating(value);
    console.log(value);
    // Determine the emotion based on the selected rating
    if (value >= 4) {
      setEmotion({
        title: "happy",
        icon: <BiHappyAlt size="24px" color="green" />,
      });
    } else if (value >= 2) {
      setEmotion({
        title: "neutral",
        icon: <BsEmojiNeutral size="24px" color="grey" />,
      });
    } else {
      setEmotion({
        title: "sad",
        icon: <BiSad size="24px" color="orange" />,
      });
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Rate Passenger</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          {emotion && (
            <p className="fw-semibold">
              You are {emotion.icon} feeling {emotion.title}.
            </p>
          )}
          {/* <Rating
            emptySymbol={<AiOutlineStar size="38px" color="#FFD700" />}
            fullSymbol={<AiFillStar size="38px" color="#FFD700" />}
            initialRating={rating}
            onClick={handleRatingChange}
          /> */}

          <RatingComponent
            iconSize="38px"
            readOnly={false}
            rating={rating}
            handleRatingChange={handleRatingChange}
          />
        </div>
        <form>
          <div className="form-group">
            <label>Feedback:</label>
            <textarea
              className="form-control"
              rows="3"
              value={feedback}
              onChange={handleFeedbackChange}
            ></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          title="Close"
          variant="secondary"
          className="btn btn-danger"
          onClick={handleClose}
        />

        <CustomButton
          isLoading={isLoading}
          title="Submit"
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default RatingModal;
