import React from "react";
import TodaysPaymentsTable from "./TodaysPaymentsTable";
import UnclearSattlementsPaymentTable from "./UnclearSattlementsPaymentsTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { asyncGetDailySattlements } from "../../redux/slices/SattlementSlice";
import UnclearSattlements from "./UnclearSattlements";
import TodaysSattlement from "./TodaysSattlement";
import NoUnclearDues from "./NoUnclearDues";

function Sattlements() {
  const dispatch = useDispatch();
  const dailySattlements = useSelector((state) => state.dailySattlements);

  const { data } = dailySattlements;
  const {
    unclearSattlements,
    todaysSattlement,
    paymentsList,
    unclearSettlementsPaymentsList,
  } = data;

  useEffect(() => {
    dispatch(asyncGetDailySattlements());
  }, []);

  console.log(unclearSettlementsPaymentsList, "unclearSattlementsPaymentsList");
  return (
    <div className="container-fluid">
      <div className="row">
        {!todaysSattlement &&
          unclearSattlements &&
          unclearSattlements.length === 0 && <NoUnclearDues />}
        {unclearSattlements && unclearSattlements.length == 0 && (
          <>
            {todaysSattlement && <TodaysSattlement data={todaysSattlement} />}
            {paymentsList && paymentsList.length > 0 && (
              <div className="col-lg-12">
                <div className="card card-block card-stretch card-height">
                  {" "}
                  <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title mb-0">
                        Payments you received today
                      </h4>
                    </div>
                    {/* <Link to="add" className="float-right btn btn-sm btn-primary">
                <i className="fa fa-plus-circle" /> Add Passenger
              </Link>{" "} */}
                  </div>
                  <TodaysPaymentsTable />
                </div>
              </div>
            )}
          </>
        )}
        {unclearSattlements && unclearSattlements.length > 0 && (
          <UnclearSattlements data={unclearSattlements} />
        )}
        {unclearSettlementsPaymentsList &&
          unclearSettlementsPaymentsList.length > 0 && (
            <div className="col-lg-12">
              <div className="card card-block card-stretch card-height">
                {" "}
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title mb-0">
                      {/* Payments you received on {unclearSattlement.date} */}
                      Payments List
                    </h4>
                  </div>
                  {/* <Link to="add" className="float-right btn btn-sm btn-primary">
                <i className="fa fa-plus-circle" /> Add Passenger
              </Link>{" "} */}
                </div>
                <UnclearSattlementsPaymentTable />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default Sattlements;
