import React, { useEffect, useRef, useState } from "react";
import "./chat.css";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../services/socket";
import { asyncGetChat, messageReceived } from "../../redux/slices/rideSlice";
import moment from "moment/moment";
import { BUCKET_PUBLIC_URL } from "../../constants";

function Chat() {
  const dispatch = useDispatch();
  const currentRide = useSelector((state) => state?.rides?.currentRide);
  const SelfAuth = useSelector((state) => state?.auth?.userInfo?._id);
  console.log(SelfAuth, "SelfAuth");
  const { _id, passenger } = currentRide;
  const chatMessage = useSelector((state) => state?.rides?.chatMessages);
  console.log(chatMessage, "chatMessage");

  useEffect(() => {
    dispatch(asyncGetChat(_id));
  }, []);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessage]);

  const [textValue, setTextValue] = useState("");

  const sentMessage = () => {
    socket.emit("send-message", {
      ride: _id,
      content: textValue,
      user: SelfAuth,
      userType: "Driver",
    });
    setTextValue(" ");
  };

  useEffect(() => {
    socket.on(
      "message-received",
      (res) => {
        dispatch(messageReceived(res));
        console.log(res, "res");
      },
      []
    );

    return () => {
      socket.off("message-received");
    };
  }, []);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="chat-window">
          <div className="chat-cont-right">
            <div className="chat-header">
              <a
                id="back_user_list"
                href="javascript:void(0)"
                className="back-user-list"
              >
                <i className="material-icons"></i>
              </a>
              <div className="media d-flex">
                <div className="media-img-wrap">
                  <div className="avatar avatar-online">
                    <img
                      src={`${BUCKET_PUBLIC_URL}${passenger?.profileImageUrl}`}
                      alt="User Image"
                      className="avatar-img rounded-circle w-100 h-100"
                    />
                  </div>
                </div>
                <div className="media-body">
                  <div className="user-name">
                    {" "}
                    {passenger?.firstName} {passenger?.lastName}{" "}
                  </div>
                </div>
              </div>
              <div className="chat-options">
                <a href="javascript:void(0)">
                  <i className="material-icons"></i>
                </a>
                <a href="javascript:void(0)">
                  <i className="material-icons"></i>
                </a>
                <a href="javascript:void(0)">
                  <i className="material-icons"></i>
                </a>
              </div>
            </div>

            <div className="chat-body">
              <div className="chat-scroll" ref={chatContainerRef}>
                <ul className="list-unstyled">
                  {chatMessage?.map((items, index) => {
                    return (
                      <>
                        <li
                          className={`${
                            items?.sender?._id === SelfAuth
                              ? "media sent"
                              : "media d-flex received"
                          }`}
                        >
                          {!items?.sender?._id === SelfAuth && (
                            <div className="avatar">
                              <img
                                src="assets/img/profiles/avatar-02.jpg"
                                alt="User Image"
                                className="avatar-img rounded-circle"
                              />
                            </div>
                          )}
                          <div className="media-body">
                            <div
                              className={`msg-box ${
                                chatMessage[index - 1]?.sender?._id !==
                                items?.sender?._id
                                  ? "boxEnd"
                                  : ""
                              }`}
                            >
                              <div>
                                <p> {items?.content} </p>
                                <ul className="chat-msg-info">
                                  <li>
                                    <div className="chat-time">
                                      <span>
                                        {" "}
                                        {moment(items?.updatedAt).format(
                                          "HH:mm"
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="chat-footer">
              <div className="input-group">
                <input
                  type="text"
                  className="input-msg-send form-control"
                  placeholder="Type something"
                  onChange={(e) => setTextValue(e.target.value)}
                  value={textValue}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn msg-send-btn"
                    onClick={sentMessage}
                    disabled={textValue === ""}
                  >
                    <i className="fas fa-paper-plane" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
