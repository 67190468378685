import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import authSlice from "./slices/authSlice";
import sidebarSlice from "./slices/sidebarSlice";
import driverSlice from "./slices/driverSlice";
import passengerSlice from "./slices/passengerSlice";
import userSlice from "./slices/userSlice";
import cabSlice from "./slices/cabSlice";
import rideSlice from "./slices/rideSlice";
import locationSlice from "./slices/locationSlice";
import rideRequestSlice from "./slices/rideRequestSlice";
import vehicleCategorySlice from "./slices/vehicleCategorySlice";
import profileSlice from "./slices/profileSlice";
import cabDocumentSlice from "./slices/cabDocumentSlice";
import personalDocumentSlice from "./slices/personalDocumentSlice";
import SattlementSlice from "./slices/SattlementSlice";
import walletSlice from "./slices/walletSlice";
import settingSlice from "./slices/settingSlice";
import countrySlice from "./slices/countrySlice";
import { NotificationAPI } from "./services/notificationAPI";
import { DocumentAPI } from "./services/documentAPI";

const store = configureStore({
  reducer: {
    [NotificationAPI.reducerPath]: NotificationAPI.reducer,
    [DocumentAPI.reducerPath]: DocumentAPI.reducer,
    // products: Products,
    // cart: CartSlice,
    auth: authSlice,
    profiles: profileSlice,
    location: locationSlice,
    drivers: driverSlice,
    passengers: passengerSlice,
    users: userSlice,
    cabs: cabSlice,
    rides: rideSlice,
    rideRequests: rideRequestSlice,
    sidebar: sidebarSlice,
    vehicleCategories: vehicleCategorySlice,
    cabDocuments: cabDocumentSlice,
    personalDocuments: personalDocumentSlice,
    dailySattlements: SattlementSlice,
    wallet: walletSlice,
    settings: settingSlice,
    country: countrySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      NotificationAPI.middleware,
      DocumentAPI.middleware
    ),
});
setupListeners(store.dispatch);

export default store;
