import React, { useEffect } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
// import AdminDashboard from "./pages/adminPages/AdminDashboardPage";

import "./assets/css/all.min.css";

import "./assets/css/backend-bundle.min.css";
import "./assets/css/backend.css";
// import "./assets/css/all.min.css";
// import "https://cdn.jsdelivr.net/npm/remixicon@3.4.0/fonts/remixicon.css";
// import "./assets/css/select2.min.css";
// import "./assets/css/jquery-confirm.min.css";
// import "./assets/css/intlTelInput.css";
import "./assets/css/custom.css";

// import "./assets/css/all.min.css";
// import "./assets/css/bootstrap-icons.css";
// import "./assets/css/bootstrap-timepicker.min.css";
// import "./assets/css/datepicker.min.css";
// import "./assets/css/font-awesome.css";
// import "./assets/css/font-awesome.min.css";
// import "./assets/css/main.css";
// import "./assets/css/main.css.map";
// import "./assets/css/main.min.css";
// import "./assets/css/select2.min.css";
// import "./assets/css/simple-line-icons.css";
// import "./assets/css/custom-css.css";
// import "./assets/css/login.css";

// import "./assetsgeneral/css/font-awesome.css";
// import "./assetsgeneral/css/font-awesome.min.css";
// import "./assetsgeneral/css/style.css";
// import "./assetsgeneral/fonts/fontawesome-webfont.eot";
// import "./assetsgeneral/fonts/fontawesome-webfont.svg";
// import "./assetsgeneral/fonts/fontawesome-webfont.ttf";
// import "./assetsgeneral/fonts/fontawesome-webfont.woff";
// import "./assetsgeneral/fonts/fontawesome-webfont.woff2";
// import "./assetsgeneral/fonts/fontawesome-webfont.woff2";

// import AdminBookingPage from "./pages/adminPages/AdminBookingPage";
// import AdminDispatchingPage from "./pages/adminPages/AdminDispatchingPage";
// import AdminClientViewPage from "./pages/adminPages/AdminClientViewPage";
// import AdminReportsPage from "./pages/adminPages/AdminReportsPage";
// import AdminTrackPage from "./pages/adminPages/AdminTrackPage";
// import AdminSettingViewCompanyInfoPage from "./pages/adminPages/AdminSettingViewCompanyInfoPage";
// import AdminSettingVehiclePage from "./pages/adminPages/AdminSettingVehiclePage";
// import AdminSettingInvoicingPage from "./pages/adminPages/AdminSettingInvoicingPage";
// import AdminSettingPaymentsPage from "./pages/adminPages/AdminSettingPaymentsPage";
// import AdminUserPage from "./pages/adminPages/AdminUserPage";
// import AdminPermissionCreatePermission from "./pages/adminPages/AdminPermissionCreatePermission";
// import AdminPermissionPermissionPage from "./pages/adminPages/AdminPermissionPermissionPage";
// import AdminAddUserPage from "./pages/adminPages/AdminAddUserPage";
// import AdminBookingTomorrowPage from "./pages/adminPages/AdminBookingTomorrowPage";
// import AdminBookingTodayPage from "./pages/adminPages/AdminBookingTodayPage";
// import AdminUserViewPage from "./pages/adminPages/AdminUserViewPage";
// import LoginPage from "./pages/loginPage/LoginPage";
// import AdminClientAddComponentPage from "./pages/adminPages/AdminClientAddComponentPage";
// import AdminClientEditComponentPage from "./pages/adminPages/AdminClientEditComponentPage";
// import AdminAddVehiclePage from "./pages/adminPages/AdminAddVehiclePage";
// import Demo from "./pages/Demo";
// import Demo2 from "./pages/Demo2";
// import ModalTesting from "./pages/adminPages/ModalTesting";
// import AdminDispatchingSchedullingOrderPage from "./pages/adminPages/AdminDispatchingSchedullingOrderPage";
import { useDispatch } from "react-redux";
// import Unauthorized from "./pages/Unauthorized";
// import Checkuser from "./pages/Checkuser";
// import AdminRoutePage from "./pages/adminPages/AdminRoutesPage";
// import AdminClientFullDetailPage from "./pages/adminPages/AdminClientFullDetailPage";
// import AdminVehicleFullDetailPage from "./pages/adminPages/AdminVehicleFullDetailPage";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import AdminSettingGenerateInvoicePage from "./pages/adminPages/AdminSettingGenerateInvoicePage";
// import AdminAddRoutePage from "./pages/adminPages/AdminAddRoutePage";
// import AdminOffHourSettingPage from "./pages/adminPages/AdminOffHourSettingPage";
// import AdminRouteDetailsPage from "./pages/adminPages/AdminRouteDetailsPage";
// import AdminEditRoutePage from "./pages/adminPages/AdminEditRoutePage";
// import AdminCreateReturnRoutePage from "./pages/adminPages/AdminCreateReturnRoutePage";
// import AdminRoutesLogPage from "./pages/adminPages/AdminRoutesPage/AdminRoutesLogPage";

// import AdminDispatchingPendingOrderPage from "./pages/adminPages/AdminDispatchingPendingOrderPage";
// import Demo3 from "./pages/Demo3";
// import AdminDispatchEditPage from "./pages/adminPages/AdminDispatchEditPage";
// import AdminMessageComposePage from "./pages/adminPages/AdminMessageComposePage";
// import AdminMessageInboxViewPage from "./pages/adminPages/AdminMessageInboxViewPage";
// import AdminMessagingThreadPage from "./pages/adminPages/AdminMessagingThreadPage";
// import AdminNotificationsPage from "./pages/adminPages/AdminNotificationsPage";
// import AdminBookingView from "./pages/adminPages/AdminBookingView";
// import AdminOtherSettingPage from "./pages/adminPages/AdminOtherSettingPage";
// import AdminUserLogInActivitiesPage from "./pages/adminPages/AdminUserLogInActivitiesPage";
// import AdminPermissionEditModalPage from "./pages/adminPages/AdminPermissionEditModalPage";
import Rides from "./pages/Rides";

import StatsPage from "./pages/Stats";
import AuthLayout from "./layout/authLayout";
import LoginPage from "./pages/loginPage/LoginPage";
// import Faq from "./pages/landingWebsitePages/Faq";

import { Toaster } from "react-hot-toast";
import Test from "./pages/test";
import Documents from "./pages/Documents";
import RideRequestsPage from "./pages/RideRequests";
import DashboardPage from "./pages/DashboardPage";

import {
  asyncGetUserLocation,
  hasLocationPermission,
} from "./redux/slices/locationSlice";

import PublicLayout from "./layout/publicLayout";
import AccountSetup from "./pages/AccountSetup";
import RideDetails from "./pages/RideDetails";
import PageUnderDevelopment from "./pages/PageUnderDevelopment";
import ProfilePage from "./pages/ProfilePage";
import Sattlements from "./pages/Sattlements";
// import Documents from "./pages/Documents";
// import PersonalDocuments from "./pages/PersonalDocuments";
// import CabDocuments from "./pages/CabDocuments";
// import EditCabDocuments from "./pages/EditCabDocuments";
// import EditPersonalDocuments from "./pages/EditPersonalDocuments";
// import AddPersoanlDocuments from "./pages/AddPersonalDocuments";
// import AddCabDocuments from "./pages/AddCabDocuments";
import Wallet from "./pages/wallet";
import { asyncGetCompanySettings } from "./redux/slices/settingSlice";
import Home from "./pages/home";
import Chat from "./pages/ChatPage/inbox";
import RideRequestDetails from "./pages/RideRequestDetails";

// import RideDetails from "./pages/";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetCompanySettings());
  }, [dispatch]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        handleSuccess,
        handleError
        // dispatch(asyncGetUserLocation)
      );
      // console.log(coords);
    } else {
      console.log("Geolocation is not available");
      dispatch(hasLocationPermission(false));
    }
  }, [dispatch, navigator.geolocation]);

  const handleSuccess = (position) => {
    dispatch(hasLocationPermission(true));

    console.log(position);
    const { latitude, longitude } = position.coords;
    dispatch(asyncGetUserLocation({ latitude, longitude }));
  };

  const handleError = (err) => {
    console.log(err);
    dispatch(hasLocationPermission(false));
  };

  // toast.configure({
  //   position: "top-right",
  //   duration: 4000,
  // });

  return (
    <>
      <div>
        <Toaster />
      </div>
      {/* <AdminHeader />
      <AdminSidebar /> */}
      <Routes>
        <Route
          path=""
          element={
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          }
        >
          <Route path="/rides" element={<Rides />} />
          <Route path="/rides/:id" element={<RideDetails />} />
          {/* <Route path="/documents/add" element={<AddPersoanlDocuments />} /> */}
          {/* <Route path="/cab-documents/add" element={<AddCabDocuments />} /> */}
          <Route path="/wallet" element={<Wallet />} />
          {/* <Route path="/passengers" element={<Passengers />} /> */}
          {/* <Route path="/passengers/add" element={<AddPassenger />} /> */}
          {/* <Route path="/drivers" element={<Drivers />} /> */}
          {/* <Route path="/drivers/add" element={<AddDriverPage />} /> */}
          {/* <Route path="/users" element={<Users />} /> */}
          {/* <Route path="/users/add" element={<AddUserPage />} /> */}
          {/* <Route path="/cabs" element={<Cabs />} /> */}
          {/* <Route path="/cabs/add" element={<AddUserPage />} /> */}
          <Route path="/ride-requests" element={<RideRequestsPage />} />
          <Route path="/ride-requests/:id" element={<RideRequestDetails />} />
          <Route path="/documents" element={<Documents />} />
          {/* <Route path="/cab-documents" element={<CabDocuments />} /> */}
          {/* <Route
            path="/cab-documents/edit/:id"
            element={<EditCabDocuments />}
          /> */}
          {/* chat */}
          <Route path="/chat" element={<Chat />} exact />
          {/* <Route
            path="/documents/edit/:id"
            element={<EditPersonalDocuments />}
          /> */}
          {/* <Route path="/documents" element={<Documents />} /> */}
          <Route path="/sattlements" element={<Sattlements />} />
          <Route path="/stats" element={<StatsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/dashboard" element={<DashboardPage />} />

          <Route path="*" element={<PageUnderDevelopment />} />
        </Route>

        <Route
          path=""
          element={
            <PublicLayout>
              <Outlet />
            </PublicLayout>
          }
        >
          <Route index element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/account-setup" element={<AccountSetup />} />
          <Route path="/test" element={<Test />} />
        </Route>
      </Routes>
    </>
  );
};
export default App;
