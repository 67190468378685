import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import signinImg from "../../assets/images/Frame.png";
// import signinImg from '../../assetsgeneral/images/Frame.png'
import signinLogo from "../../assets/images/logo.png";
// import signinLogo from '../../assetsgeneral/images/CONNECT CARE TRANS-04.png'
// import signinLogo from '../../assetsgeneral/images/CONNECTCARE_WhiteLogo.png'
import {
  asyncResendOtp,
  asyncSendOtp,
  asyncSignIn,
  asyncVerifyOtp,
  changeNumber,
} from "../../redux/slices/authSlice";
import DotsLoader from "../../components/loaders/DotsLoader";

// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { useRef } from "react";    
import CustomButton from "../../components/CustomButton";
import { asyncGetCountry } from "../../redux/slices/countrySlice";
import LoadingComponent from "../../components/loaders/FullScreenLoader";
import PageLoader from "../../components/loaders/PageLoader";

const otpExpiryTime = 120;

const LoginPage = () => {
  let navigate = useNavigate();             
  const auth = useSelector((state) => state.auth);
  const countriesList = useSelector((state) => state.country);

  const { isLoading, otpSent, otpResent, userInfo, newRegister } = auth;              

  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(otpExpiryTime);
          
  const location = useSelector((state) => state.location);            
  const { country } = location;

  const [data, setData] = useState({
    email: "",              
    password: "",
  });
  const [phone, setPhone] = useState("");
  const isMounted = useRef(true);           

  const resendOtpHandler = () => {
    isMounted.current && dispatch(asyncResendOtp());
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,            
    });
  };

  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();                            
    // dispatch(asyncSignIn(data));
    if (otpSent)
      dispatch(
        asyncVerifyOtp({
          phone: phone.includes("+") ? phone : "+" + phone,
          otp,
        })                             
      );
    else
      dispatch(
        asyncSendOtp({ phone: phone.includes("+") ? phone : "+" + phone })
      );
  };       

  useEffect(() => {
    if (userInfo) {
      if (newRegister) {
        navigate("/account-setup");            
      } else {     
        navigate("/dashboard");
      }
    }
  }, [userInfo]);
              
  useEffect(() => {
    if (otpSent) {
      setTimeLeft(otpExpiryTime);
      setOtp("");               
    }
  }, [otpSent]);

  useEffect(() => {
    if (otpResent) {           
      setTimeLeft(otpExpiryTime);
      setOtp("");
    }
  }, [otpResent]);
  // const resendOtpSuccessHandler = () => {
  //   setTimeLeft(otpExpiryTime);
  // };
  useEffect(() => {             
    isMounted.current = true;
    const interval = setInterval(() => {
      isMounted.current &&
        otpSent &&
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 0) {    
            return prevTimeLeft - 1;
          }
          // clearInterval(interval) // Clear the interval when timeLeft reaches 0
          // setOtpExpired(true)
          return 0;
        });
    }, 1000);
    return () => {                   
      isMounted.current = false;
      clearInterval(interval);
    };           
  }, [otpSent]);

  useEffect(() => {
    dispatch(asyncGetCountry());             
  }, []);

  // if (countriesList?.list.length === 0) {
  //   return (
  //     <>
  //       <div className="d-flex justify-content-center align-items-center h-100">
  //         <LoadingComponent />
  //       </div>
  //     </>
  //   );
  // }

  return (
    <>
      {/* Jumbotron  */}
      <div className="centered-container">
        <div className="text-lg-start">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-7 mb-5 mb-lg-0">      
                <div className="row">
                  <div className="sig-img">
                    {/* <img src={signinImg} alt="" width="100%" id="sigimg" /> */}
                  </div>
                </div>
              </div>                

              <div className="col-lg-5 mb-lg-0">
                <div className="card-2 bg-brand">
                  <div className="card-body py-5 px-3 px-md-5">
                    <form onSubmit={handleSubmit}>
                      <div className="text-center p-4">        
                        <Link to="">      
                          <img
                            src={"/assets/images/logo-white.png"}
                            alt=""         
                            height={80}
                            // id="logsedf"
                          />
                        </Link>
                      </div>
                      <div className="text-white mb-3">             
                        <h4>Drive with us!</h4>
                      </div>

                      <div className=" mb-4">
                        <label
                          className="form-label label-text"        
                          htmlFor="form3Example3"
                        >
                          Phone Number
                          {otpSent && (
                            <>
                              {" -- "}
                              <Link                                          
                                onClick={() => dispatch(changeNumber())}
                                to=""
                              >
                                Change              
                              </Link>
                            </>
                          )}
                        </label>         

                        {countriesList?.list.length > 0 && (
                          <div className="input-group mb-3">                               
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={phone}
                              onChange={setPhone}
                              // country={"PK"}             
                              country={country?.short_name.toLowerCase()}
                              // defaultCountry={country?.short_name}
                              // onlyCountries={["so", "ke", "ug", "us", "pk"]}
                              onlyCountries={countriesList?.list?.map((items) =>
                                items.shortName.toString().toLowerCase()
                              )}
                              prefix="+"
                              addInternationalOption={false}
                              className=" phone-input"
                              // className="w-100"
                              required={true}
                              disabled={otpSent}
                            />
                          </div>
                        )}
                      </div>
                      {otpSent && (
                        <>
                          <div className="mb-4">
                            <label
                              className="form-label label-text"
                              htmlFor="form3Example3"
                            >
                              Enter OTP
                            </label>
                            <div className="input-group mb-3">
                              <OtpInput
                                containerStyle="w-100 justify-content-between"
                                // className="form-control"
                                inputType="number"
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => (
                                  <input
                                    {...props}
                                    className="otp-input"
                                    disabled={timeLeft <= 0}
                                    placeholder="0"
                                  />
                                )}
                              />
                            </div>
                          </div>

                          <div className="">
                            {timeLeft > 0 ? (
                              <div className="d-grid"></div>
                            ) : (
                              <div className="d-grid"></div>
                            )}
                          </div>
                          <div className="">
                            {timeLeft > 0 ? (
                              <p className="text-center fw-bold text-primary">
                                OTP will expire in:{" "}
                                {timeLeft > 59
                                  ? `0${Math.floor(timeLeft / 60)}:${
                                      timeLeft % 60 < 10
                                        ? `0${timeLeft % 60}`
                                        : timeLeft % 60
                                    }`
                                  : `00:${
                                      timeLeft < 10 ? `0${timeLeft}` : timeLeft
                                    }`}
                              </p>
                            ) : (
                              <p className="text-center fw-bold text-danger">
                                OTP is expired, Please get a new one!{" "}
                                <Link to="" onClick={resendOtpHandler}>
                                  Resend OTP
                                </Link>
                              </p>
                            )}
                          </div>
                        </>
                      )}
                      <div className="d-grid mb-4">
                        <CustomButton
                          type="submit"
                          disabled={isLoading}
                          isLoading={isLoading}
                          title={otpSent ? "Verify OTP" : "Get OTP"}
                          className="btn btn-dark"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
