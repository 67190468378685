import React from "react";
import { Link } from "react-router-dom";
import PageSubTitle from "../../components/pageSubTitle";

function StatsPage() {
  return (
    <div className="px-4 py-2 border-top-0 emp-dashboard">
      <PageSubTitle pageTitle="Statistics" />

      <div class="container-fluid m-0 p-0">
        <div class="row row-md m-0 p-0 ">

          <div class="col-lg-4 col-md-6 col-xs-12 p-0">
            <div class="box box-block bg-primary mb-2 p-3">
              <div class="t-content">
                <h4 class="text-uppercase mb-1">Total Revenue</h4>
                <h1 class="mb-1"> $0 </h1>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-xs-12 p-0">
            <div class="box box-block bg-success mb-2 mx-2 p-3">
              <div class="t-content">
                <h4 class="text-uppercase mb-1">Today Ride</h4>
                <h1 class="mb-1">0</h1>
              </div>
            </div>
          </div>
          
          <div class="col-lg-4 col-md-6 col-xs-12 p-0">
            <div class="box box-block bg-primary mb-2 p-3">
              <div class="t-content">
                <h4 class="text-uppercase mb-1">Completed Ride</h4>
                <h1 class="mb-1">0</h1>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-xs-12 p-0">
            <div class="box box-block bg-warning mb-2 p-3">
              <div class="t-content">
                <h4 class="text-uppercase mb-1">Total Ride</h4>
                <h1 class="mb-1">7</h1>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-xs-12 p-0">
            <div class="box box-block bg-warning mb-2 mx-2 p-3">
              <div class="t-content">
                <h4 class="text-uppercase mb-1">Ride Accepted</h4>
                <h1 class="mb-1">0</h1>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-xs-12 p-0">
            <div class="box box-block bg-success mb-2 p-3">
              <div class="t-content">
                <h4 class="text-uppercase mb-1">Cancel Ride</h4>
                <h1 class="mb-1">7</h1>
              </div>
            </div>
          </div>

          {/* <div class="col-lg-4 col-md-6 col-xs-12 p-0">
            <div class="box box-block bg-primary mb-2 p-3">
              <div class="t-content">
                <h4 class="text-uppercase mb-1">Scheduled Ride</h4>
                <h1 class="mb-1">0</h1>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default StatsPage;
