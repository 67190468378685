import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { asyncGetDriverProfile } from "../../redux/slices/profileSlice";
import PageLoader from "../../components/loaders/PageLoader";
import RatingComponent from "../../components/RatingComponent";
import { BUCKET_BASE_URL, BUCKET_PUBLIC_URL } from "../../constants";

function ProfilePage() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const driverProfile = useSelector((state) => state.profiles.driverProfile);
  const { isLoading, data } = driverProfile;

  const { personalDetails, vehicleDetails, stats, ratings } = data;

  useEffect(() => {
    dispatch(asyncGetDriverProfile({ id: auth.userInfo._id }));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch">
            <div className="card-body p-0">
              <div className="d-flex justify-content-between align-items-center p-3">
                <h5 className="font-weight-bold">Driver Profile</h5>
                {/* <Link
                  to="/drivers"
                  className="float-right btn btn-sm btn-primary"
                >
                  <i className="fa fa-angle-double-left" /> Back
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="row">
          <div className="col-lg-4">
            <div className="card card-block p-card">
              <div className="profile-box">
                <div className="profile-card rounded">
                  <img
                    src={`${BUCKET_PUBLIC_URL}${personalDetails?.profileImageUrl}`}
                    alt="01.jpg"
                    className="avatar-100 rounded d-block mx-auto img-fluid mb-3"
                  />
                  <h3 className="font-600 text-white text-center mb-0">
                    {personalDetails?.firstName} {personalDetails?.lastName}
                  </h3>
                  <p className="text-white text-center mb-5">
                    <span className="text-capitalize badge bg-success ">
                      active
                    </span>
                  </p>
                </div>
                <div className="pro-content rounded">
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-envelope" />
                    </div>
                    <p className="mb-0 eml">{personalDetails?.email}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <p className="mb-0">{personalDetails?.phone}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i
                        className={`fas fa-${personalDetails?.gender?.toLowerCase()}`}
                      />
                    </div>
                    <p className="mb-0">{personalDetails?.gender}</p>
                  </div>
                </div>
              </div>
            </div>
            {vehicleDetails && (
              <div className="card card-block">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title mb-0">Vehicle Details</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <img
                        src={
                          BUCKET_PUBLIC_URL + vehicleDetails.category.imageUrl
                        }
                        alt="service-detail"
                        className="img-fluid avatar-60 rounded-small"
                      />
                    </div>
                    <div className="col-9">
                      <p className="mb-0">
                        {vehicleDetails.category
                          ? vehicleDetails.category.name
                          : "N/A"}
                      </p>
                      <p className="mb-0">
                        {vehicleDetails.year} {vehicleDetails.make}{" "}
                        {vehicleDetails.model} ( {vehicleDetails.color} )
                      </p>
                      <p className="mb-0">{vehicleDetails.plateNumber}</p>
                      {/* <p className="mb-0">848454046</p> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-8">
            <div className="row">
              {/* <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Wallet Balance</p>
                      <p />
                      <h5> USD 10 </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Completed Rides</p>
                      <p />
                      <h5>{stats?.completedRides} </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Cancelled Rides</p>
                      <p />
                      <h5> {stats?.cancelledRides} </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Ratings</p>
                      <p />
                      <h5>
                        {ratings?.average} ( {ratings?.count} ){" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Joined On</p>
                      <p />
                      <h5>
                        {moment(personalDetails?.createdAt)?.format(
                          "DD MMM, YYYY"
                        )}{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Total Earnings</p>
                      <p />
                      <h5>N/A </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Cash Earnings</p>
                      <p />
                      <h5>N/A </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Wallet Earning</p>
                      <p />
                      <h5>0.00₹ </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Admin Commission</p>
                      <p />
                      <h5>4.96₹ </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Wallet Earnings</p>
                      <p />
                      <h5>N/A </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Wallet Balance</p>
                      <p />
                      <h5>1494.04₹ </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <div className="">
                        <p className="mb-1">Total Withdraw</p>
                        <p />
                        <h5>N/A </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul class="list-group">
              {/* {ratings &&
                ratings.list.map((x) => (
                  <li class="list-group-item">
                    <h5 class="mb-1">Shayan Ali</h5>
                    <RatingComponent iconSize={22} rating={x.rating} readOnly />
                    <p class="mb-1">{x.feedback}</p>
                    <small></small>
                  </li>
                ))} */}
            </ul>
            {/* <div className="row">
              <div className="card card-block">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title mb-0">Add Wallet</h4>
                  </div>
                </div>
                <div className="card-body">
                  <form
                    method="POST"
                    action="https://meetmighty.com/mobile/mighty-taxi/save-wallet-fund/3"
                    acceptCharset="UTF-8"
                  >
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="Im4ffDJo4SFzeKGZVyaULBwqIkLq9OiQi22jObPU"
                    />
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label htmlFor="type" className="form-control-label">
                          Type <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control select2js select2-hidden-accessible"
                          required=""
                          id="type"
                          name="type"
                          data-select2-id="type"
                          tabIndex={-1}
                          aria-hidden="true"
                        >
                          <option value="credit" data-select2-id={2}>
                            Credit
                          </option>
                          <option value="debit">Debit</option>
                        </select>
                        <span
                          className="select2 select2-container select2-container--default"
                          dir="ltr"
                          data-select2-id={1}
                          style={{ width: "242.131px" }}
                        >
                          <span className="selection">
                            <span
                              className="select2-selection select2-selection--single"
                              role="combobox"
                              aria-haspopup="true"
                              aria-expanded="false"
                              tabIndex={0}
                              aria-disabled="false"
                              aria-labelledby="select2-type-container"
                            >
                              <span
                                className="select2-selection__rendered"
                                id="select2-type-container"
                                role="textbox"
                                aria-readonly="true"
                                title="Credit"
                              >
                                Credit
                              </span>
                              <span
                                className="select2-selection__arrow"
                                role="presentation"
                              >
                                <b role="presentation" />
                              </span>
                            </span>
                          </span>
                          <span
                            className="dropdown-wrapper"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="form-group col-md-4">
                        <label
                          htmlFor="transaction_type"
                          className="form-control-label"
                        >
                          Transaction Type{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control select2js select2-hidden-accessible"
                          required=""
                          id="transaction_type"
                          name="transaction_type"
                          tabIndex={-1}
                          aria-hidden="true"
                          data-select2-id="transaction_type"
                        >
                          <option value="topup" data-select2-id={5}>
                            Top-up
                          </option>
                        </select>
                        <span
                          className="select2 select2-container select2-container--default"
                          dir="ltr"
                          data-select2-id={4}
                          style={{ width: "100%" }}
                        >
                          <span className="selection">
                            <span
                              className="select2-selection select2-selection--single"
                              role="combobox"
                              aria-haspopup="true"
                              aria-expanded="false"
                              tabIndex={0}
                              aria-disabled="false"
                              aria-labelledby="select2-transaction_type-container"
                            >
                              <span
                                className="select2-selection__rendered"
                                id="select2-transaction_type-container"
                                role="textbox"
                                aria-readonly="true"
                              >
                                <span className="select2-selection__placeholder">
                                  Select Transaction Type
                                </span>
                              </span>
                              <span
                                className="select2-selection__arrow"
                                role="presentation"
                              >
                                <b role="presentation" />
                              </span>
                            </span>
                          </span>
                          <span
                            className="dropdown-wrapper"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="amount" className="form-control-label">
                          Amount <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          min={0}
                          step="any"
                          required=""
                          placeholder="Amount"
                          name="amount"
                          type="number"
                          id="amount"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="description"
                          className="form-control-label"
                        >
                          Description
                        </label>
                        <textarea
                          className="form-control textarea"
                          rows={2}
                          placeholder="Description"
                          name="description"
                          cols={50}
                          id="description"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <hr />
                    <input
                      className="btn btn-md btn-primary float-right"
                      type="submit"
                      defaultValue="Save"
                    />
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfilePage;
