import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";
import MobileMoneyDetailsModal from "../../components/modals/MobileMoneyDetailsModal";

function TodaysSattlement({ data }) {
  const [showMobileMoneyPopup, setShowMobileMoneyPopup] = useState(false);

  const closeMobileMoneyPopup = () => {
    setShowMobileMoneyPopup(false);
  };
  return (
    <div className="col-lg-12 text-center">
      {/* <div className="card card-block card-stretch card-height">
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title mb-0">Sattlement Amount Due Today</h4>
          </div>
          
        </div>
        
        <div class="card-body">
          <div></div>
          
          <div class="alert alert-info fsz-2">
            <strong>Today's Date: </strong> {data?.date}
          </div>
          <div class="alert alert-success fsz-2">
            <strong>Due Amount: </strong> ${data?.totalCommissionDue}
          </div>
          
          <p>
            This is the settlement amount due till now. Please make the payment
            to the Cabtify when you close for the day.
          </p>
        </div>
      </div> */}

      <div class="card card-block">
        <div class="card-header mb-4 ">
          <h4>Sattlement Amount Due Today</h4>
        </div>
        <div class="card-body">
          <h3 className="mx-auto">
            {data.currency} {data.totalCommissionDue}
          </h3>
        </div>
        <div className=" mb-4">
          <div className="d-flex justify-content-evenly py-3">
            {data.flatFeeAmount && data.flatFeeAmount > 0 ? (
              <div class="p-4 bg-light card w-30">
                <h5>Flat Fee Amount:</h5>
                <h5>
                  {data.currency} {data?.flatFeeAmount}
                </h5>
              </div>
            ) : (
              <></>
            )}
            <div class="p-4 bg-light card w-30">
              <h5>Total Amount Collected:</h5>
              <h5>
                {data.currency} {data?.totalAmountCollected}
              </h5>
            </div>
            <div class="p-4 bg-light card w-30">
              <h5>Total Cash Collected:</h5>
              <h5>
                {data.currency} {data?.totalCashCollected}
              </h5>
            </div>
            <div class="p-4 bg-light card w-30">
              <h5>Amount Received In Wallet:</h5>
              <h5>
                {data.currency} {data?.totalAmountReceivedInWallet}
              </h5>
            </div>
          </div>
          {/* <CustomButton
            type="submit"
            title="Add Funds"
            class="btn btn-primary"
            // onClick={() => setShowPopup(true)}
          />{" "} */}
          <CustomButton
            type="submit"
            title="Mobile Money Details"
            class="btn btn-primary"
            onClick={() => setShowMobileMoneyPopup(true)}
          />
        </div>
      </div>
      <MobileMoneyDetailsModal
        show={showMobileMoneyPopup}
        closePopup={closeMobileMoneyPopup}
      />
    </div>
  );
}

export default TodaysSattlement;
