import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetPersonalDocuments = createAsyncThunk(
  "asyncGetPersonalDocuments",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { id, callback } = obj;
    try {
      const response = await axiosPrivate.get(`/documents/v2/driver`);

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdatePersonalDocuments = createAsyncThunk(
  "updateDriver",
  async (
    { id, obj, callback },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/documents/${id}`, obj);
      toast.success("Document updated successfully!");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncPostDriversDocuments = createAsyncThunk(
  "asyncPostDriversDocuments",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj, callback } = payload;
    try {
      const response = await axiosPrivate.post("/documents", obj);
      toast.success("Driver created Documents Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const personalDocumentSlice = createSlice({
  name: "personalDocument",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetPersonalDocuments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetPersonalDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetPersonalDocuments.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for add document
    builder.addCase(asyncPostDriversDocuments.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(asyncPostDriversDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.list = action.payload;
      state.isError = false;
      state.message = null;
    });

    // Cases for update document
    builder.addCase(asyncUpdatePersonalDocuments.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(asyncUpdatePersonalDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
  },
  reducers: {},
});

export const {} = personalDocumentSlice.actions;
export default personalDocumentSlice.reducer;
