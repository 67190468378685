import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetUsers = createAsyncThunk(
  "getUsers",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/users");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateUser = createAsyncThunk(
  "createUsers",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.post("/users", obj);
      toast.success("User updated Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncUpdateUser = createAsyncThunk(
  "updateUser",
  async (
    { id, data },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/users/${id}`, data);
      toast.success("User created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
  },
  extraReducers: (builder) => {
    // Cases for Get Users

    builder.addCase(asyncGetUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetUsers.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Add User
    builder.addCase(asyncCreateUser.pending, (state, action) => {
      // console.log("Error", action);

      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateUser.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: "User Created Successfully!" };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreateUser.rejected, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update User
    builder.addCase(asyncUpdateUser.pending, (state, action) => {
      // console.log("Error", action);

      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateUser.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: "User Updated Successfully!" };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdateUser.rejected, (state, action) => {
      // console.log("Error", action);
      state.update = { isLoading: false, message: action.payload };
    });
  },
  reducers: {
    // userLogout(state, action) {
    //   state.userInfo = null;
    // },
  },
});

export const {
  // userLogout
} = userSlice.actions;
export default userSlice.reducer;
