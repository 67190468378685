import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import socket from "../services/socket";
import { notifyFailure, notifySuccess } from "./toast";
import { driverOnlineStatus } from "../redux/slices/authSlice";

function ToggleSwitch({ title }) {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { isOnline } = auth;
  // const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    if (isOnline) {
      socket.emit("driver-offline", auth.userInfo._id);
    } else {
      socket.emit("driver-online", auth.userInfo._id);
    }
  };

  useEffect(() => {
    socket.on("driver-status", (res) => {
      if (res.success) {
        console.log("isOnleine==>", isOnline);
        if (res.status === "Online") {
          dispatch(driverOnlineStatus(true));
        } else {
          dispatch(driverOnlineStatus(false));
        }
        // setIsChecked((prev) => !prev);
        notifySuccess(`You are now ${res.status}!`);
      } else {
        notifyFailure(res.message);
      }
      console.log("res==>", res);
    });
    console.log("res==>");
    return () => {
      socket.off("driver-status");
    };
  }, []);

  // useEffect(() => {
  //   if (isChecked) {
  //     socket.emit("driver-online", auth.userInfo._id, (res) => {
  //       if (res.success) {
  //         notifySuccess("You are now online!");
  //       }
  //     });
  //   } else {
  //     socket.emit("driver-offline", auth.userInfo._id, (res) => {
  //       if (res.success) {
  //         notifySuccess("You are now offline!");
  //       }
  //     });
  //   }
  // }, [isChecked]);

  return (
    <div
      className="toggle-switch"
      data-toggle="tooltip"
      data-placement="bottom"
      title={isOnline ? "You are online!" : "You are offline!"}
    >
      {/* <button
        type="button"
        class="btn btn-secondary"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Tooltip on bottom"
      >
        Tooltip on bottom
      </button> */}
      <label
        htmlFor="#toggle-switch"
        className={`status ${isOnline ? "on" : "off"}`}
      >
        {isOnline ? "Online" : "Offline"}
      </label>
      <label className="switch-cont">
        <input
          type="checkbox"
          id="toggle-switch"
          checked={isOnline}
          onChange={toggleSwitch}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default memo(ToggleSwitch);
