import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MapComponent from "../../components/mapComponents/mapComponent";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../services/socket";
import { notifyFailure, notifySuccess } from "../../components/toast";

import {
  asyncGetCurrentRide,
  cancelRide,
  updateRideDetails,
} from "../../redux/slices/rideSlice";

// import RideRequestPopup from "../../components/modals/rideRequestModal";
import RideCompleteModal from "../../components/modals/rideCompleteModal";
import RatingModal from "../../components/modals/ratingModal";
import axiosPrivate from "../../services/api";
import RidePaymenteModal from "../../components/modals/ridePaymentModal";
import { Button } from "react-bootstrap";
import ChatPopup from "../../components/modals/chatPopup";
import { BUCKET_PUBLIC_URL } from "../../constants";

function RideView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  // const { isOnline, userInfo } = auth;

  const location = useSelector((state) => state.location);

  const rides = useSelector((state) => state.rides);
  const { currentRide } = rides;

  const [openRideCompletePopup, setOpenRideCompletePopup] = useState(false);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [openRatingPopup, setOpenRatingPopup] = useState(false);
  const [ratingDone, setRatingDone] = useState(false);

  const isSubmitLoading = false;
  // const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [ChatOpen, setChatOpen] = useState(false);

  // const [showRatingPopup, setShowRatingPopup] = useState(false);
  const [rating, setRating] = useState(3);
  const [feedback, setFeedback] = useState("");
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    // if (currentRide?.status === "Completed") {
    // if (
    //   currentRide?.status !== "Completed" &&
    //   currentRide?.rideActions.find((x) => x.type == "rideEnded")
    // ) {
    //   setOpenRideCompletePopup(true);
    //   //   navigate("/ride-summary");
    // }

    if (currentRide?.status === "trip_ended") {
      setOpenRideCompletePopup(true);
    }
    if (currentRide?.paymentDetails?.paymentDone) {
      setOpenRatingPopup(true);
    }
  }, [currentRide]);

  const handleSubmit = async () => {
    console.log("ride det=>", currentRide);
    const ratingPostData = {
      ratingTo: currentRide.passenger._id || currentRide.passenger._id,
      modelNameOfTheRatingToUser: "Passenger",
      ratingBy: currentRide.driver?._id || currentRide.driver._id,
      modelNameOfTheRatingByUser: "Driver",
      ride: currentRide._id || currentRide._id,
      rating: rating,
      feedback: feedback,
    };
    try {
      setIsloading(true);
      const respones = await axiosPrivate.post(`/ratings`, ratingPostData);
      setIsloading(false);
      setOpenRatingPopup(false);
      notifySuccess("Rating done successfully!");
      setRatingDone(true);
      console.log("Response data:", respones);
      console.log(isloading);
    } catch (error) {
      notifyFailure(error.response.data.message);
      setIsloading(false);
      // Handle any errors that occur during the request
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    dispatch(asyncGetCurrentRide());
  }, [dispatch]);

  // Create a function to generate the Google Maps directions URL
  const generateDirectionsLink = ({ from, to }) => {
    const origin = `${from[0]},${from[1]}`;
    const destination = `${to[0]},${to[1]}`;
    const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
    return url;
  };

  // Handle the click event to open Google Maps in a new tab/window
  const handleMapDirections = () => {
    if (currentRide.route.reachedAtPickup) {
      const directionsUrl = generateDirectionsLink({
        from: currentRide?.rideRequest?.pickup?.location?.coordinates,
        to: currentRide?.rideRequest?.dropoff?.location?.coordinates,
      });
      window.open(directionsUrl, "_blank");
    } else {
      const directionsUrl = generateDirectionsLink({
        from: location.coordinates,
        to: currentRide?.rideRequest?.pickup?.location?.coordinates,
      });
      window.open(directionsUrl, "_blank");
    }
  };

  const cancelRideHandler = (res) => {
    socket.emit(
      "cancel-ride-v2",
      {
        rideId: currentRide._id,
        passenger: currentRide.passenger._id,
        driver: currentRide.driver._id,
        cancelDetails: {
          cancelledBy: auth.userInfo._id,
          modelNameOfTheUserType: "Driver",
          cancelReason: "Other",
          timestamp: new Date(),
        },
      },
      (res) => {
        console.log("ride cancelled==>", res);
        dispatch(cancelRide(currentRide._id));
        notifySuccess("Ride cancelled successfully!");
      }
    );
  };

  const rideActionsArr = [
    {
      name: "Ride Booked",
      handler: () => {
        // socket.emit(
        //   "ride-action",
        //   {
        //     driverId: auth.userInfo._id,
        //     action: {
        //       name: "Reached at Pickup",
        //       messageForDriver:
        //         "You have reached to the pickup location, pick the passenger!",
        //       messageForPassenger: "Driver reached to your pickup location!",
        //     },
        //   },
        //   (res) => {
        //     if (res.success) {
        //       dispatch(updateCurrentRideActions(res.data));
        //       notifySuccess("Action status updated successfully !");
        //     }
        //   }
        // );
      },
    },
    {
      name: "Arrived",
      type: "arrivedAtPickup",

      handler: () => {
        setIsActionLoading("arrivedAtPickup");
        socket.emit(
          "arrived-at-pickup-v2",
          {
            driver: auth.userInfo._id,
            // action: {
            //   type: "reachedAtPickup",
            //   name: "Reached at Pickup",
            //   messageForDriver:
            //     "You have reached to the pickup location, pick the passenger!",
            //   messageForPassenger: "Driver reached to your pickup location!",
            //   location: { type: "Point", coordinates: location.coordinates },
            // },
            // reachedAtPickup: true,
          },
          (res) => {
            console.log("action res=>", res);
            if (res.success) {
              // dispatch(updateCurrentRideActions(res.data));
              setIsActionLoading(false);

              dispatch(updateRideDetails(res.ride));

              notifySuccess(res.message);
            }
          }
        );
      },
    },
    {
      name: "Pickup Customer",
      type: "startTrip",

      handler: () => {
        setIsActionLoading("startTrip");

        socket.emit(
          "start-trip-v2",
          {
            driver: auth.userInfo._id,
            // action: {
            //   type: "passengerPicked",
            //   name: "Passenger Picked",
            //   messageForDriver:
            //     "Passenger is picked, now you can start the ride!",
            //   messageForPassenger:
            //     "Passenger is picked, ride will start anytime soon!",
            //   location: { type: "Point", coordinates: location.coordinates },
            // },
            // passengerPicked: true,
          },
          (res) => {
            if (res.success) {
              // dispatch(updateCurrentRideActions(res.data));
              setIsActionLoading(false);

              dispatch(updateRideDetails(res.ride));

              notifySuccess(res.message);
            }
          }
        );
      },
    },
    {
      name: "End Trip",
      type: "endTrip",

      handler: () => {
        setIsActionLoading("endTrip");

        socket.emit(
          "end-trip-v3",
          {
            driver: auth.userInfo._id,
            // action: {
            //   type: "rideStarted",
            //   name: "Ride Started",
            //   messageForDriver: "Ride in progress, drive safely!",
            //   messageForPassenger: "Ride is in progress!",
            //   location: { type: "Point", coordinates: location.coordinates },
            // },
            // rideStarted: true,
          },
          (res) => {
            if (res.success) {
              // dispatch(updateCurrentRideActions(res.data));
              setIsActionLoading(false);
              dispatch(updateRideDetails(res.ride));
              notifySuccess(res.message);
            }
          }
        );
        // socket.emit(
        //   "end-trip-and-charge-v3",
        //   {
        //     driver: auth.userInfo._id,
        //     // action: {
        //     //   type: "rideStarted",
        //     //   name: "Ride Started",
        //     //   messageForDriver: "Ride in progress, drive safely!",
        //     //   messageForPassenger: "Ride is in progress!",
        //     //   location: { type: "Point", coordinates: location.coordinates },
        //     // },
        //     // rideStarted: true,
        //   },
        //   (res) => {
        //     if (res.success) {
        //       // dispatch(updateCurrentRideActions(res.data));
        //       setIsActionLoading(false);
        //       dispatch(updateRideDetails(res.ride));
        //       notifySuccess(res.message);
        //     }
        //   }
        // );
      },
    },
    // {
    //   name: "End Ride",
    //   type: "rideEnded",

    //   handler: () => {
    //     setIsActionLoading("rideEnded");

    //     socket.emit(
    //       "ride-action",
    //       {
    //         driverId: auth.userInfo._id,
    //         action: {
    //           type: "rideEnded",
    //           name: "Ride Ended",
    //           messageForDriver: "Ride has ended, please collect the fare!",
    //           messageForPassenger: "Ride has ended, waiting for payment!",
    //           location: { type: "Point", coordinates: location.coordinates },
    //         },
    //         rideEnded: true,
    //       },
    //       (res) => {
    //         if (res.success) {
    //           // dispatch(updateCurrentRideActions(res.data));
    //           setIsActionLoading(false);
    //           dispatch(updateRideDetails(res.ride));

    //           notifySuccess(res.message);
    //         }
    //       }
    //     );
    //   },
    // },
    // {
    //   name: "Payment Received",
    //   type: "paymentDone",

    //   handler: () => {
    //     setIsActionLoading("paymentDone");

    //     socket.emit(
    //       "ride-action",
    //       {
    //         driverId: auth.userInfo._id,
    //         action: {
    //           type: "paymentDone",
    //           name: "Payment Received",
    //           messageForDriver: "Payment is done!",
    //           messageForPassenger: "Payment is done!",
    //           location: { type: "Point", coordinates: location.coordinates },
    //         },
    //         paymentDone: true,
    //       },
    //       (res) => {
    //         if (res.success) {
    //           // dispatch(updateCurrentRideActions(res.data));
    //           setIsActionLoading(false);
    //           setOpenRideCompletePopup(false);

    //           dispatch(updateRideDetails(res.ride));

    //           notifySuccess(res.message);
    //         }
    //       }
    //     );
    //   },
    // },
  ];

  const submitHandler = () => {
    if (openPaymentPopup === "Cash") {
      socket.emit(
        "pay-with-cash-v3",
        {
          driver: auth.userInfo._id,
          // action: {
          //   type: "paymentDone",
          //   name: "Payment Done",
          //   messageForDriver: "Payment is done!",
          //   messageForPassenger: "Payment is done!",
          //   location: { type: "Point", coordinates: location.coordinates },
          // },
          // paymentDone: true,
          // paymentMethod: "Cash",
        },
        (res) => {
          console.log("res==>", res);
          if (res.success) {
            // dispatch(updateCurrentRideActions(res.data));
            dispatch(updateRideDetails(res.ride));
            setOpenRideCompletePopup(false);
            setOpenPaymentPopup(false);
            notifySuccess("Ride completed successfully !");
          }
        }
      );
    } else {
      // socket.emit(
      //   "ride-action",
      //   {
      //     driverId: auth.userInfo._id,
      //     action: {
      //       type: "paymentDone",
      //       name: "Payment Done",
      //       messageForDriver: "Payment is done!",
      //       messageForPassenger: "Payment is done!",
      //       location: { type: "Point", coordinates: location.coordinates },
      //     },
      //     paymentDone: true,
      //     paymentMethod: "Wallet",
      //   },
      //   (res) => {
      //     console.log("res==>", res);
      //     if (res.success) {
      //       // dispatch(updateCurrentRideActions(res.data));
      //       dispatch(updateRideDetails(res.data));
      //       setOpenRideCompletePopup(false);
      //       setOpenPaymentPopup(false);
      //       notifySuccess("Ride completed successfully !");
      //     }
      //   }
      // );

      socket.emit(
        "pay-with-wallet-v3",
        {
          driverId: auth.userInfo._id,
          // action: {
          //   type: "payUsingWallet",
          //   name: "Payment Using Wallet",
          //   messageForDriver: "Payment is done!",
          //   messageForPassenger: "Payment is done!",
          //   location: { type: "Point", coordinates: location.coordinates },
          // },
          // paymentDone: true,
          // paymentMethod: "Wallet",
        },
        (res) => {
          console.log("res==>", res);
          if (res.success) {
            // dispatch(updateCurrentRideActions(res.data));
            dispatch(updateRideDetails(res.data));
            setOpenRideCompletePopup(false);
            setOpenPaymentPopup(false);
            notifySuccess("Ride completed successfully !");
          } else {
            notifySuccess(res.message);
          }
        }
      );
    }
    // socket.emit(
    //   "ride-action",
    //   {
    //     driverId: auth.userInfo._id,
    //     action: {
    //       type: "paymentDone",
    //       name: "Payment Done",
    //       messageForDriver: "Payment is done!",
    //       messageForPassenger: "Payment is done!",
    //       location: { type: "Point", coordinates: location.coordinates },
    //     },
    //     paymentDone: true,
    //   },
    //   (res) => {
    //     if (res.success) {
    //       // dispatch(updateCurrentRideActions(res.data));
    //       dispatch(updateRideDetails(res.data));
    //       setOpenRideCompletePopup(false);
    //       setOpenPaymentPopup(false);
    //       notifySuccess("Ride completed successfully !");
    //     }
    //   }
    // );
  };

  // const submitRatingHandler = () => {};

  const viewRideDetailsHandler = () => {
    navigate(`/rides/${currentRide._id}`);
  };

  const payWithCashHandler = () => {
    setOpenPaymentPopup("Cash");
    setOpenRideCompletePopup(false);
  };
  const payWithWalletHandler = () => {
    // setOpenPaymentPopup("Wallet");
    // setOpenRideCompletePopup(false);
    socket.emit(
      "ride-action",
      {
        driverId: auth.userInfo._id,
        action: {
          type: "paymentDone",
          name: "Payment Done",
          messageForDriver: "Payment is done!",
          messageForPassenger: "Payment is done!",
          location: { type: "Point", coordinates: location.coordinates },
        },
        paymentDone: true,
        paymentMethod: "Wallet",
      },
      (res) => {
        console.log("res==>", res);
        if (res.success) {
          // dispatch(updateCurrentRideActions(res.data));
          dispatch(updateRideDetails(res.data));
          setOpenRideCompletePopup(false);
          setOpenPaymentPopup(false);
          notifySuccess("Ride completed successfully !");
        }
      }
    );
  };

  console.log("Action loading==>", isActionLoading);
  return (
    <div>
      <>
        <div className=" mb-2">
          <div className="d-flex align-items-center justify-content-between">
            <p>
              {currentRide.rideActions[currentRide.rideActions.length - 1] &&
                currentRide.rideActions[currentRide.rideActions.length - 1]
                  .messageForDriver}
            </p>
            <CustomButton
              // type="submit"
              // disabled={isLoading}
              // isLoading={isLoading}
              onClick={handleMapDirections}
              title={"Open Map"}
              className={"btn btn-success"}
            />
          </div>
          {/* <div>
                <h6>
                  Navigate to passenger pickup location and start the ride!{" "}
                </h6>
              </div> */}
        </div>
        {/* {hasActiveRide ? ( */}
        <div className="row">
          <div className="col-12 col-md-6 ">
            <div className="mb-4">
              <h6>Ride Actions:</h6>
              <div className="d-flex justify-content-start flex-wrap">
                {rideActionsArr.map((action, index) => {
                  const actionDone = currentRide.rideActions[index];
                  // console.log("ride actio found==", actionDone);
                  return (
                    <CustomButton
                      className={`btn mr-2 mb-2 ${
                        actionDone ? "btn-success" : "btn-warning"
                      }`}
                      title={action.name}
                      icon={
                        actionDone ? (
                          <i class="fa fa-check" aria-hidden="true"></i>
                        ) : (
                          <i class="fa fa-clock" aria-hidden="true"></i>
                        )
                      }
                      onClick={
                        !actionDone &&
                        currentRide.rideActions.length === index &&
                        action.handler
                      }
                      isLoading={isActionLoading === action.type}
                    />
                  );
                })}
              </div>
            </div>
            {/* Passenger Information */}
            <div className="mb-3">
              <h6>Passenger Information:</h6>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    src={
                      BUCKET_PUBLIC_URL +
                      currentRide?.passenger?.profileImageUrl
                    }
                    width={100}
                    alt="Passenger"
                    className="mr-3 rounded-circle"
                  />
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Name:</span>{" "}
                      {currentRide?.passenger?.firstName}{" "}
                      {currentRide?.passenger?.lastName}
                    </p>
                    {/* <p className="mb-0">Email: john@example.com</p> */}
                    <p className="mb-0">
                      <span className="fw-bold">Phone:</span>{" "}
                      {currentRide?.passenger?.phone}
                    </p>
                  </div>
                </div>
                <Button
                  variant="warning"
                  onClick={() => setChatOpen(!ChatOpen)}
                >
                  Chat
                </Button>{" "}
              </div>

              {ChatOpen && (
                <ChatPopup show={ChatOpen} setChatOpen={setChatOpen} />
              )}
            </div>

            {/* Pickup and Dropoff Locations */}
            <div className="mb-3">
              <h6>Pickup Location:</h6>
              <div className="d-flex align-items-start">
                <img
                  src={"/assets/images/location-icon.png"}
                  width={20}
                  alt="Location"
                  className="mr-2 mt-1"
                />
                <p className="mb-0">
                  {currentRide?.rideRequest?.pickup?.address?.formattedAddress}
                </p>
              </div>
            </div>
            <div className="mb-3">
              <h6>Dropoff Location:</h6>
              <div className="d-flex align-items-center">
                <img
                  src={"/assets/images/location-icon.png"}
                  width={20}
                  alt="Location"
                  className="mr-2 mt-1"
                />
                <p className="mb-0">
                  {currentRide?.rideRequest?.dropoff?.address?.formattedAddress}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              {/* Fare and Distance */}
              <div className="mr-5">
                <h6>Estimated Distance / Duration:</h6>
                <p>
                  {currentRide?.rideRequest?.distance} /{" "}
                  {currentRide?.rideRequest?.duration}
                </p>
              </div>
              <div className="mb-3">
                <h6>Estimated Fare:</h6>
                <p>
                  {currentRide?.rideRequest?.fareDetails?.totalFare}{" "}
                  {currentRide?.rideRequest?.fareDetails?.currency}
                </p>
              </div>
            </div>
            {!currentRide.route.arrivedAtPickup && (
              <CustomButton
                // type="submit"
                // disabled={isLoading}
                // isLoading={isLoading}
                onClick={cancelRideHandler}
                title={"Cancel Ride"}
                className={"btn btn-danger"}
              />
            )}
            <div className="d-flex gap-2">
              {currentRide.status === "completed" && (
                <CustomButton
                  // type="submit"
                  // disabled={isLoading}
                  // isLoading={isLoading}
                  onClick={viewRideDetailsHandler}
                  title={"View Details"}
                  className={"btn btn-primary"}
                />
              )}

              {!ratingDone && currentRide.route.rideEnded && (
                <CustomButton
                  // type="submit"
                  // disabled={isLoading}
                  // isLoading={isLoading}
                  onClick={() => setOpenRatingPopup(true)}
                  title={"Rate Now"}
                  className={"btn btn-primary"}
                />
              )}
            </div>

            {/* <div className="">
                  <h6>Ride Actions:</h6>
                  <div className="d-flex justify-con           tent-start">
                    <CustomButton
                      className="btn btn-warning mr-3"
                      title="Pick Customer"
                    />
                    <CustomButton
                      className="btn btn-warning mr-3"
                      title="Start Ride"
                      icon={
                        <i
                          class="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                      }
                    />
                    <CustomButton
                      className="btn btn-warning mr-3"
                      title="End Ride"
                      icon={<i class="fa fa-check" aria-hidden="true"></i>}
                    />
                  </div>
                </div> */}
          </div>
          <div className="col-12 col-md-6">
            <MapComponent />
          </div>
        </div>
      </>

      {openRideCompletePopup && (
        <RideCompleteModal
          show={openRideCompletePopup}
          // isSubmitLoading={isSubmitLoading}
          // submitHandler={submitHandler}
          payWithCashHandler={payWithCashHandler}
          payWithWalletHandler={payWithWalletHandler}
        />
      )}

      {openPaymentPopup && (
        <RidePaymenteModal
          show={openPaymentPopup}
          closeModal={() => {
            setOpenPaymentPopup(false);
            setOpenRideCompletePopup(true);
          }}
          isSubmitLoading={isSubmitLoading}
          submitHandler={submitHandler}
        />
      )}

      {openRatingPopup && (
        <>
          <RatingModal
            show={openRatingPopup}
            handleSubmit={handleSubmit}
            rating={rating}
            handleClose={() => setOpenRatingPopup(false)}
            setRating={setRating}
            feedback={feedback}
            setFeedback={setFeedback}
            isLoading={isloading}
            isSubmitLoading={isSubmitLoading}
          />
        </>
      )}
    </div>
  );
}

export default RideView;
