import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetCabsDocuments = createAsyncThunk(
  "asyncGetCabsDocuments",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { id, callback } = obj;
    try {
      const response = await axiosPrivate.get(`/documents/vehicle/${id}`);

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateDocumentsCab = createAsyncThunk(
  "asyncUpdateDocumentsCab",
  async (
    { id, obj, callback },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    console.log(id, "sliceID");
    try {
      const response = await axiosPrivate.patch(`/documents/${id}`, obj);
      toast.success("Cab created Update Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);


export const asyncPostCabDocuments = createAsyncThunk(
  "asyncPostCabDocuments",
  async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const {obj, callback} = payload
    console.log(obj, "obj")
    try {
      const response = await axiosPrivate.post("/documents", obj );
      toast.success("Cab created document Successfully");
      callback && callback()
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const cabDocumentSlice = createSlice({
  name: "cabsDocuments",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetCabsDocuments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetCabsDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetCabsDocuments.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
  reducers: {},
});

export const {
  // userLogout
} = cabDocumentSlice.actions;
export default cabDocumentSlice.reducer;
