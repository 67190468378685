import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import CustomButton from "../CustomButton";
import { useSelector } from "react-redux";

function RidePaymenteModal({
  show,
  closeModal,
  isSubmitLoading,
  submitHandler,
}) {
  const rides = useSelector((state) => state.rides);
  const { currentRide } = rides;
  const [timeLeft, setTimeLeft] = useState(30); // Initial time left in seconds

  return (
    <Modal show={show} centered>
      <div className="small-modal">
        <Modal.Header className="d-flex justify-content-between">
          <h5>Ride Payment using {show}</h5>
          {/* <h6 className="text-danger">{formattedTimeLeft}</h6> */}
        </Modal.Header>
        <Modal.Body>
          {show === "Cash" ? (
            <>
              {/* Fare and Distance */}
              <div className="mb-3">
                <h5 className="f-24">
                  Total Ride Fare is{" "}
                  <b>
                    {currentRide.route?.fareDetails?.totalFare?.toFixed(2)}{" "}
                    {currentRide.route?.fareDetails?.currency}
                  </b>
                </h5>
              </div>

              <div className="mb-3">
                <p>
                  <span className="fw-semibold">Note:</span> Please collect the
                  total fare amount from rider through Cash or Mobile Money and
                  submit.
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>{" "}
        <Modal.Footer>
          <CustomButton
            title="Close"
            className="btn btn-danger"
            onClick={closeModal}
          />
          <CustomButton
            title="Submit"
            className="btn btn-success"
            isLoading={isSubmitLoading}
            onClick={submitHandler}
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default RidePaymenteModal;
