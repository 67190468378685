import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetCabs = createAsyncThunk(
  "getCabs",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/vehicles");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateCab = createAsyncThunk(
  "createCabs",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.post("/vehicles", obj);
      toast.success("Cab created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncUpdateCab = createAsyncThunk(
  "updateCab",
  async (
    { id, data },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/vehicles/${id}`, data);
      toast.success("Cab created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const cabSlice = createSlice({
  name: "cabs",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
  },
  extraReducers: (builder) => {
    // Cases for Get Cabs

    builder.addCase(asyncGetCabs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetCabs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetCabs.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Add Cab
    builder.addCase(asyncCreateCab.pending, (state, action) => {
      // console.log("Error", action);

      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateCab.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: "Cab Created Successfully!" };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreateCab.rejected, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update Cab
    builder.addCase(asyncUpdateCab.pending, (state, action) => {
      // console.log("Error", action);

      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateCab.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: "Cab Updated Successfully!" };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdateCab.rejected, (state, action) => {
      // console.log("Error", action);
      state.update = { isLoading: false, message: action.payload };
    });
  },
  reducers: {
    // userLogout(state, action) {
    //   state.userInfo = null;
    // },
  },
});

export const {
  // userLogout
} = cabSlice.actions;
export default cabSlice.reducer;
