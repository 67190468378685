import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import PageSubTitle from "../../components/pageSubTitle";
import MapComponent from "../../components/mapComponents/mapComponent";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../services/socket";
// import { notifySuccess } from "../../components/toast";
// import { driverOnlineStatus } from "../../redux/slices/authSlice";
import {
  asyncGetCurrentRide,
  // cancelRide,
  // setRideDetails,
  // updateRideDetails,
  // updateCurrentRideActions,
} from "../../redux/slices/rideSlice";

function NoRideView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
                 
  const auth = useSelector((state) => state.auth);
  const { isOnline } = auth;

  // const location = useSelector((state) => state.location);

  const rides = useSelector((state) => state.rides);
  const { currentRide } = rides;

  const toggleOnlineStatus = () => {
    if (isOnline) {
      socket.emit("driver-offline", auth.userInfo._id);
    } else {
      socket.emit("driver-online", auth.userInfo._id);
    }
  };

  useEffect(() => {
    if (currentRide?.status === "Completed") {
      navigate("/ride-summary");
    }
  }, [navigate, currentRide]);

  useEffect(() => {
    dispatch(asyncGetCurrentRide());
    // socket.on("driver-status", (res) => {
    //   if (res.success) {
    //     console.log("isOnleine==>", isOnline);
    //     if (res.status === "Online") {
    //       dispatch(driverOnlineStatus(true));
    //     } else {
    //       dispatch(driverOnlineStatus(false));
    //     }
    //     // setIsChecked((prev) => !prev);
    //     notifySuccess(`You are now ${res.status}!`);
    //   }
    //   console.log("res==>", res);
    // });
    // console.log("res==>");
    // return () => {
    //   socket.off("driver-status");
    // };
  }, [dispatch]);

  // Create a function to generate the Google Maps directions URL
  // const generateDirectionsLink = ({ from, to }) => {
  //   const origin = `${from[0]},${from[1]}`;
  //   const destination = `${to[0]},${to[1]}`;
  //   const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
  //   return url;
  // };

  // Handle the click event to open Google Maps in a new tab/window
  // const handleMapDirections = () => {
  //   if (currentRide.route.reachedAtPickup) {
  //     const directionsUrl = generateDirectionsLink({
  //       from: currentRide?.rideRequest?.pickup?.location?.coordinates,
  //       to: currentRide?.rideRequest?.dropoff?.location?.coordinates,
  //     });
  //     window.open(directionsUrl, "_blank");
  //   } else {
  //     const directionsUrl = generateDirectionsLink({
  //       from: location.coordinates,
  //       to: currentRide?.rideRequest?.pickup?.location?.coordinates,
  //     });
  //     window.open(directionsUrl, "_blank");
  //   }
  // };

  // const cancelRideHandler = (res) => {
  //   socket.emit(
  //     "cancel-ride",
  //     {
  //       rideId: currentRide._id,
  //       passenger: currentRide.passenger._id,
  //       driver: currentRide.driver._id,
  //       cancelDetails: {
  //         cancelledBy: auth.userInfo._id,
  //         modelNameOfTheUserType: "Driver",
  //         cancelReason: "Test",
  //         timestamp: new Date(),
  //       },
  //     },
  //     (res) => {
  //       console.log("ride cancelled==>", res);
  //       dispatch(cancelRide(currentRide._id));
  //       notifySuccess("Ride cancelled successfully!");
  //     }
  //   );
  // };

  // const rideActionsArr = [
  //   {
  //     name: "Ride Booked",
  //     handler: () => {
  //       // socket.emit(
  //       //   "ride-action",
  //       //   {
  //       //     driverId: auth.userInfo._id,
  //       //     action: {
  //       //       name: "Reached at Pickup",
  //       //       messageForDriver:
  //       //         "You have reached to the pickup location, pick the passenger!",
  //       //       messageForPassenger: "Driver reached to your pickup location!",
  //       //     },
  //       //   },
  //       //   (res) => {
  //       //     if (res.success) {
  //       //       dispatch(updateCurrentRideActions(res.data));
  //       //       notifySuccess("Action status updated successfully !");
  //       //     }
  //       //   }
  //       // );
  //     },
  //   },
  //   {
  //     name: "Reached at Pickup",
  //     handler: () => {
  //       socket.emit(
  //         "ride-action",
  //         {
  //           driverId: auth.userInfo._id,
  //           action: {
  //             type: "reachedAtPickup",
  //             name: "Reached at Pickup",
  //             messageForDriver:
  //               "You have reached to the pickup location, pick the passenger!",
  //             messageForPassenger: "Driver reached to your pickup location!",
  //             location: { type: "Point", coordinates: location.coordinates },
  //           },
  //           reachedAtPickup: true,
  //         },
  //         (res) => {
  //           if (res.success) {
  //             // dispatch(updateCurrentRideActions(res.data));
  //             dispatch(updateRideDetails(res.data));

  //             notifySuccess("Action status updated successfully !");
  //           }
  //         }
  //       );
  //     },
  //   },
  //   {
  //     name: "Pick Passenger",
  //     handler: () => {
  //       socket.emit(
  //         "ride-action",
  //         {
  //           driverId: auth.userInfo._id,
  //           action: {
  //             type: "passengerPicked",
  //             name: "Passenger Picked",
  //             messageForDriver:
  //               "Passenger is picked, now you can start the ride!",
  //             messageForPassenger:
  //               "Passenger is picked, ride will start anytime soon!",
  //             location: { type: "Point", coordinates: location.coordinates },
  //           },
  //           passengerPicked: true,
  //         },
  //         (res) => {
  //           if (res.success) {
  //             // dispatch(updateCurrentRideActions(res.data));
  //             dispatch(updateRideDetails(res.data));

  //             notifySuccess("Action status updated successfully !");
  //           }
  //         }
  //       );
  //     },
  //   },
  //   {
  //     name: "Start Ride",
  //     handler: () => {
  //       socket.emit(
  //         "ride-action",
  //         {
  //           driverId: auth.userInfo._id,
  //           action: {
  //             type: "rideStarted",
  //             name: "Ride Started",
  //             messageForDriver: "Ride in progress, drive safely!",
  //             messageForPassenger: "Ride is in progress!",
  //             location: { type: "Point", coordinates: location.coordinates },
  //           },
  //           rideStarted: true,
  //         },
  //         (res) => {
  //           if (res.success) {
  //             // dispatch(updateCurrentRideActions(res.data));
  //             dispatch(updateRideDetails(res.data));

  //             notifySuccess("Action status updated successfully !");
  //           }
  //         }
  //       );
  //     },
  //   },
  //   {
  //     name: "End Ride",
  //     handler: () => {
  //       socket.emit(
  //         "ride-action",
  //         {
  //           driverId: auth.userInfo._id,
  //           action: {
  //             type: "rideEnded",
  //             name: "Ride Ended",
  //             messageForDriver: "Ride has ended!",
  //             messageForPassenger: "Ride has ended!",
  //             location: { type: "Point", coordinates: location.coordinates },
  //           },
  //           rideEnded: true,
  //         },
  //         (res) => {
  //           if (res.success) {
  //             // dispatch(updateCurrentRideActions(res.data));
  //             dispatch(updateRideDetails(res.data));

  //             notifySuccess("Action status updated successfully !");
  //           }
  //         }
  //       );
  //     },
  //   },
  // ];

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <p>            
          {isOnline
            ? "You are online and now you can receive ride ride requests!"
            : "You are offline and you can not recieve ride requests!"}
        </p>{" "}
        <CustomButton    
          // type="submit"
          // disabled={isLoading}
          // isLoading={isLoading}
          onClick={toggleOnlineStatus}
          title={isOnline ? "Go Offline" : "Go Online"}     
          className={isOnline ? "btn btn-warning" : "btn btn-success"}
        />
      </div>
      <div>
        <MapComponent />
      </div>                       
    </>
  );
}

export default NoRideView;
