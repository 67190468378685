import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const DocumentAPI = createApi({
  reducerPath: "documentAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchNotification"],
  endpoints: (builder) => ({
    getDriverRequiredAndSubmittedDocuments: builder.query({
      query: () => ({
        url: `/documents/getDriverRequiredAndSubmittedDocuments`,
        method: "GET",
        // params: { page, pageSize },
      }),
    }),

    // updateNotification: builder.mutation({
    //   query: (id) => ({
    //     url: `/notifications/${id}`,
    //     method: "PATCH",
    //   }),
    // }),
  }),
});

export const { useGetDriverRequiredAndSubmittedDocumentsQuery } = DocumentAPI;
