import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";
import { GOOGLE_API_KEY } from "../../constants";

export const asyncGetCountry = createAsyncThunk(
  "getCountry",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/countries");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const countrySlice = createSlice({
  name: "userCountry",
  initialState: {
    isLoading: false,
    list: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetCountry.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetCountry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetCountry.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
  reducers: {
  
  },
});

export const {} = countrySlice.actions;
export default countrySlice.reducer;
