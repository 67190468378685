import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetWallet = createAsyncThunk(
  "getWallet",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/wallets/driver");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetWalletTransactions = createAsyncThunk(
  "getWalletTransactions",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        "/wallets/transactions/driver"
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

// export const asyncRequestTopup = createAsyncThunk(
//   "getRequestTopup",
//   async (
//     { data, callback },
//     { dispatch, getState, rejectWithValue, fulfillWithValue }
//   ) => {
//     try {
//       const response = await axiosPrivate.post("/wallets/requestTopup", data);

//       callback && callback({ success: true });
//       return fulfillWithValue(response.data);
//     } catch (error) {
//       console.log("err==>", error.response.data.message);
//       toast.error(error.response.data.message);
//       callback &&
//         callback({ success: false, message: error.response.data.message });

//       throw rejectWithValue(error.response.data.message);
//     }
//   }
// );
// export const asyncGetCurrentRide = createAsyncThunk(
//   "getCurrentRide",
//   async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
//     try {
//       const response = await axiosPrivate.get("/rides/getPassengerCurrentRide");

//       return fulfillWithValue(response.data);
//     } catch (error) {
//       console.log("err==>", error.response.data.message);
//       toast.error(error.response.data.message);

//       throw rejectWithValue(error.response.data.message);
//     }
//   }
// );

// export const asyncGetRideDetails = createAsyncThunk(
//   "getRideDetails",
//   async (id, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
//     try {
//       const response = await axiosPrivate.get(`/rides/${id}`);

//       return fulfillWithValue(response.data);
//     } catch (error) {
//       console.log("err==>", error.response.data.message);
//       toast.error(error.response.data.message);

//       throw rejectWithValue(error.response.data.message);
//     }
//   }
// );

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    data: {},
    isError: false,
    transactions: {
      isLoading: false,
      list: [],
      isError: false,
    },
  },
  extraReducers: (builder) => {
    // Cases for Get Rides
    builder.addCase(asyncGetWallet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetWallet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetWallet.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for wallet transactions
    builder.addCase(asyncGetWalletTransactions.pending, (state, action) => {
      state.transactions.isLoading = true;
    });
    builder.addCase(asyncGetWalletTransactions.fulfilled, (state, action) => {
      state.transactions.isLoading = false;
      state.transactions.list = action.payload;
      state.transactions.isError = false;
      state.transactions.message = null;
    });
    builder.addCase(asyncGetWalletTransactions.rejected, (state, action) => {
      // console.log("Error", action);
      state.transactions.isLoading = false;
      state.transactions.message = action.payload;
      state.transactions.isError = true;
    });

    // Cases for topup request
    // builder.addCase(asyncRequestTopup.pending, (state, action) => {
    //   // state.isLoading = true;
    // });
    // builder.addCase(asyncRequestTopup.fulfilled, (state, action) => {
    //   // state.transactions.isLoading = false;
    //   state.transactions.list = [action.payload, ...state.transactions.list];
    //   // state.isError = false;
    //   // state.message = null;
    // });
    // builder.addCase(asyncRequestTopup.rejected, (state, action) => {
    //   // console.log("Error", action);
    //   // state.isLoading = false;
    //   // state.message = action.payload;
    //   // state.isError = true;
    // });
  },
  reducers: {},
});

export const {} = walletSlice.actions;
export default walletSlice.reducer;
