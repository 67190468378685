import React from "react";
// import { useState } from "react";
// import MobileMoneyDetailsModal from "../../components/modals/MobileMoneyDetailsModal";
// import CustomButton from "../../components/CustomButton";

function NoUnclearDues({ data }) {
  return (
    <>
      <div className="col-lg-12">
        <div className="card card-block card-stretch card-height ">
          <div className="card-header d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title mb-0">No dues on you</h4>
            </div>
            {/* <Link to="add" className="float-right btn btn-sm btn-primary">
        <i className="fa fa-plus-circle" /> Add Passenger
      </Link>{" "} */}
          </div>
          {/* <div class="card-header">
      <h5 class="card-title">Settlement Amount Due Today</h5>
    </div> */}
          <div class="card-body">
            <p>
              <strong>Note:</strong> You don't have any unclear dues yet, please
              continue getting the rides and enjoy!
            </p>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default NoUnclearDues;
