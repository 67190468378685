import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetDailySattlements = createAsyncThunk(
  "asyncGetDailySattlements",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        "/dailySattlements/getDriverTodaysSattlement"
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncsendClearSattlementRequestByDriver = createAsyncThunk(
  "asyncsendClearSattlementRequestByDriver",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { callback } = obj;
    try {
      const response = await axiosPrivate.get(
        "/dailySattlements/sendClearSattlementRequestByDriver"
      );
      callback && callback();
      toast.success("Clear sattlement request sent Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncsendClearSattlementRequestByDriverViaWallet =
  createAsyncThunk(
    "asyncsendClearSattlementRequestByDriverViaWallet",
    async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
      const { callback } = obj;
      try {
        const response = await axiosPrivate.get(
          "/dailySattlements/clearDailySattlementUsingWallet"
        );
        callback && callback();
        toast.success("Sattlement creared successfully");
        return fulfillWithValue(response.data);
      } catch (error) {
        console.log("err==>", error.response.data.message);
        toast.error(error.response.data.message);

        throw rejectWithValue(error.response.data.message);
      }
    }
  );

const sattlementSlice = createSlice({
  name: "sattlement",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    data: {},
    isError: false,
    add: {},
    update: {},
    currentRide: null,
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetDailySattlements.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetDailySattlements.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetDailySattlements.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
});

// export const {} = sattlementSlice.actions;
export default sattlementSlice.reducer;
