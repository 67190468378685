import React from "react";

function TableRow({ key, index, srNo, rowData, columns }) {
  return (
    <tr class="odd" key={key}>
      <td class="">{srNo} {")"}</td>                                    

      {columns.map((e, ind) => (

        <td class="" key={ind}>

          { e.displayField
            ? e.displayField(rowData)
            : rowData[e.key]?.length > 0
            ? rowData[e.key]
            : "N/A"}           
            
        </td>
      ))}
    </tr>
  );
}

export default TableRow;
