import React from "react";
import {
  GoogleMap,
  Polyline,
  LoadScript,
  useLoadScript,
  PolylineF,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../constants";
import { useState } from "react";

const MapWithRoute = ({
  rideWaypoints,
  waypointsToPickup,
  pickupLocation,
  dropoffLocation,
  startLocation,
}) => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };
  const [activeMarker, setActiveMarker] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    // libraries: libraries,
  });

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker);
  };
  if (!isLoaded) {
    return null; // Don't render the component until libraries are loaded
  }
  return (
    <div>
      {pickupLocation && dropoffLocation && startLocation ? ( // Check if all locations are available
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={15}
          center={{
            lat: pickupLocation[0],
            lng: pickupLocation[1],
          }} // Center the map on the first point of your route
        >
          {waypointsToPickup && (
            <PolylineF
              path={waypointsToPickup.map((x) => ({
                lat: x.coordinates[0],
                lng: x.coordinates[1],
              }))}
              geodesic={true}
              options={{
                strokeColor: "#000",
                strokeOpacity: 0.75,
                strokeWeight: 5,
                // icons: [
                //   {
                //     // icon: lineSymbol,
                //     offset: "0",
                //     repeat: "20px",
                //   },
                // ],
              }}
            />
          )}
          {rideWaypoints && (
            <PolylineF
              path={rideWaypoints.map((x) => ({
                lat: x.coordinates[0],
                lng: x.coordinates[1],
              }))}
              geodesic={true}
              options={{
                strokeColor: "blue",
                strokeOpacity: 0.75,
                strokeWeight: 5,
                // icons: [
                //   {
                //     // icon: lineSymbol,
                //     offset: "0",
                //     repeat: "20px",
                //   },
                // ],
              }}
            />
          )}

          {startLocation && (
            <MarkerF
              position={{
                lat: startLocation[0],
                lng: startLocation[1],
              }}
              onMouseOver={() => handleMarkerClick(startLocation)}
              onMouseOut={() => handleMarkerClick(null)}
            >
              {activeMarker === startLocation && (
                <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                  {/* <div> */}
                  <p className="fw-semibold">This is Driver start location!</p>
                  {/* </div> */}
                </InfoWindowF>
              )}
            </MarkerF>
          )}
          {pickupLocation && (
            <MarkerF
              position={{
                lat: pickupLocation[0],
                lng: pickupLocation[1],
              }}
              onMouseOver={() => handleMarkerClick(pickupLocation)}
              onMouseOut={() => handleMarkerClick(null)}
            >
              {activeMarker === pickupLocation && (
                <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                  <div>
                    <p className="fw-semibold">This is pickup location!</p>
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
          )}
          {dropoffLocation && (
            <MarkerF
              position={{
                lat: dropoffLocation[0],
                lng: dropoffLocation[1],
              }}
              onMouseOver={() => handleMarkerClick(dropoffLocation)}
              onMouseOut={() => handleMarkerClick(null)}
            >
              {activeMarker === dropoffLocation && (
                <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                  {/* <div> */}
                  <p className="fw-semibold">This is dropoff location!</p>
                  {/* </div> */}
                </InfoWindowF>
              )}
            </MarkerF>
          )}
        </GoogleMap>
      ) : (
        <div>
          <p>Not enough data is available to display the map.</p>
        </div>
      )}
    </div>
  );
};

export default MapWithRoute;
