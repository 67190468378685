import React, { useEffect, useState } from "react";

import MapWithRoute from "../../components/mapComponents/mapWithRoute";
import PageSubTitle from "../../components/pageSubTitle";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { asyncGetRideRequestDetails } from "../../redux/slices/rideRequestSlice";

const RideRequestDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [directions, setDirections] = useState(null);
  const rideRequests = useSelector((state) => state.rideRequests);
  const { rideRequestDetails } = rideRequests;

  useEffect(() => {
    dispatch(asyncGetRideRequestDetails(id));
    // getDirections();
  }, [dispatch, id]);

  return (
    <div className="px-4 py-2 border-top-0 emp-dashboard">
      <PageSubTitle pageTitle="Ride Request Details" />
      {rideRequestDetails ? (
        <div class="container-fluid  m-0 p-0">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title py-3">
                {rideRequestDetails.status
                  ? `The ride request was ${rideRequestDetails.status}`
                  : ""}{" "}
              </h6>

              <MapWithRoute
                startLocation={
                  rideRequestDetails.pickup
                    ? rideRequestDetails.pickup.location.coordinates
                        .slice()
                        .reverse()
                    : [0, 0]
                }
                pickupLocation={
                  rideRequestDetails?.pickup
                    ? rideRequestDetails?.pickup?.location.coordinates
                        .slice()
                        .reverse()
                    : [0, 0]
                }
                dropoffLocation={
                  rideRequestDetails.dropoff
                    ? rideRequestDetails?.dropoff?.location.coordinates
                        .slice()
                        .reverse()
                    : [0, 0]
                }
                directions={directions}
                setDirections={setDirections}
              />

              <div class="row py-3">
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Passenger:</strong>{" "}
                    {rideRequestDetails?.passenger?.firstName}{" "}
                    {rideRequestDetails?.passenger?.lastName}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Request Time:</strong>{" "}
                    {moment(rideRequestDetails.createdAt).format(
                      "DD MMM, YYYY @ hh:mm a"
                    )}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Estimated Pickup:</strong>{" "}
                    {rideRequestDetails?.pickup?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Estimated Dropoff:</strong>{" "}
                    {rideRequestDetails?.dropoff?.address?.formattedAddress}
                  </div>
                </div>
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Vehicle:</strong>{" "}
                    {rideRequestDetails.vehicle?.color}{" "}
                    {rideRequestDetails.vehicle?.make}{" "}
                    {rideRequestDetails.vehicle?.model}{" "}
                    {rideRequestDetails.vehicle?.year}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default RideRequestDetails;
