import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stepper from "./stepper";
import { Button, Container } from "react-bootstrap";
import VerificationDocuments from "./verificationDocuments";
import PersonalDetailsForm from "./personalDetails";
import VehicleDetailsForm from "./vehicleDetailsForm";
import { Navigate, useNavigate } from "react-router-dom";
import {
  asyncSubmitPersonalData,
  asyncSubmitVehicleData,
  asyncSubmitDocuments,
} from "../../redux/slices/authSlice";
import CustomButton from "../../components/CustomButton";
import hasEmptyString from "../../utils/hasEmptyString";
import toast from "react-hot-toast";
import { notifyFailure, notifySuccess } from "../../components/toast";
import { useEffect } from "react";
import axiosPrivate from "../../services/api";

const steps = [
  "Registeration",
  "Personal Details",
  "Vehicle Details",
  "Verification Documents",
];
function AccountSetup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [requiredDocumentsList, setRequiredDocumentsList] = useState(null);

  const auth = useSelector((state) => state.auth);
  const { isLoading, userInfo } = auth;

  const [personalData, setPersonalData] = useState({
    firstName: userInfo ? userInfo.firstName : "",
    lastName: userInfo ? userInfo.lastName : "",
    email: userInfo ? userInfo.email : "",
    phone: userInfo ? userInfo.phone : "",
    address: userInfo ? userInfo.address : "",
    // identityCardNumber: userInfo ? userInfo._identityCardNumber : "",
    // identityCardExpiry: userInfo ? userInfo._identityCardExpiry : "",
    drivingLicenseNumber: userInfo ? userInfo.drivingLicenseNumber : "",
    drivingLicenseExpiry: userInfo ? userInfo.drivingLicenseExpiry : "",
    isGoogleAuth: false,
    password: "",
  });

  const [vehicleData, setVehicleData] = useState({
    make: "",
    model: "",
    year: "",
    color: "",
    plateNumber: "",
  });
  const [documentsData, setDocumentsData] = useState([
    // profileImageUrl: "",
    // drivingLicenseFrontImageUrl: "",
    // drivingLicenseBackImageUrl: "",
    // identityCardFrontImageUrl: "",
    // identityCardBackImageUrl: "",
  ]);
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (activeStep == 1) {
      const { phone, ...restData } = personalData;
      dispatch(
        asyncSubmitPersonalData({
          data: { ...restData },
          callback: () => {
            // This callback function will be executed after the action is dispatched
            console.log("Action dispatched and callback executed.");
            notifySuccess("Personal details submitted successfully!");

            handleNext();
          },
        })
      );
    } else if (activeStep == 2) {
      dispatch(
        asyncSubmitVehicleData({
          data: vehicleData,
          callback: () => {
            // This callback function will be executed after the action is dispatched
            console.log("Action dispatched and callback executed.");
            notifySuccess("Vehicles details submitted successfully!");

            // handleNext();
          },
        })
      );
    } else if (activeStep == 3) {
      const isEmpty = hasEmptyString(documentsData);
      console.log(
        "is empty==>",
        documentsData,
        documentsData.filter(
          (x, i) =>
            x.frontSideUrl == "" ||
            (requiredDocumentsList[i].hasBackSide && x.backSideUrl == "")
        ).length > 0
      );
      if (
        documentsData.filter(
          (x, i) =>
            x.frontSideUrl == "" ||
            (requiredDocumentsList[i].hasBackSide && x.backSideUrl == "")
        ).length > 0
      ) {
        notifyFailure("Please upload all the documents!");
      } else {
        dispatch(
          asyncSubmitDocuments({
            data: {
              documentsData: documentsData.map((x) => ({
                ...x,
                // driver: auth.userInfo._id,
              })),
              documentsSubmitted: true,
            },
            callback: () => {
              // This callback function will be executed after the action is dispatched
              console.log("Action dispatched and callback executed.");
              notifySuccess("Documents submitted successfully!");

              navigate("/dashboard");
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    if (userInfo.vehicleDetailsSubmitted) {
      setActiveStep(3);
    } else if (userInfo.personalDetailsSubmitted) {
      setActiveStep(2);
    }
    getRequiredDocumentsList();
  }, [userInfo]);

  const getRequiredDocumentsList = async () => {
    try {
      const { data } = await axiosPrivate.get(`/requiredDocuments`);
      setRequiredDocumentsList(data);
      setDocumentsData(
        // data
        data.map((x) => ({
          // modelNameOfTheDocumentFor: x.type,
          documentType: x._id || x._id,
          // documentFor:
          //   x.type === "Driver" ? auth.userInfo._id : auth.vehicleInfo._id,
          frontSideUrl: "",
          backSideUrl: "",
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  console.log(documentsData, "docsdata");
  if (!userInfo) {
    return <Navigate to="/login" />;
  }

  console.log("info==>", userInfo, activeStep);

  return (
    <form onSubmit={submitHandler}>
      <Stepper {...{ steps, activeStep, handleBack, handleNext }}>
        {activeStep !== steps.length - 1 ? (
          <CustomButton
            variant="primary"
            type="submit"
            title="Next"
            isLoading={isLoading}
          />
        ) : (
          <CustomButton
            variant="primary"
            type="submit"
            title="Finish"
            isLoading={isLoading}
          />
        )}
      </Stepper>
      <Container className="pb-5">
        {activeStep == 1 && (
          <PersonalDetailsForm {...{ personalData, setPersonalData }} />
        )}
        {activeStep == 2 && (
          <VehicleDetailsForm {...{ vehicleData, setVehicleData }} />
        )}
        {activeStep == 3 && (
          <VerificationDocuments
            {...{ requiredDocumentsList, documentsData, setDocumentsData }}
          />
        )}
        {/* {activeStep !== steps.length - 1 ? (
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        ) : (
          <Button variant="success">Finish</Button>
        )} */}
      </Container>
    </form>
  );
}

export default AccountSetup;
