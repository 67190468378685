import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageSubTitle from "../../components/pageSubTitle";
import MapComponent from "../../components/mapComponents/mapComponent";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../services/socket";
import { notifySuccess } from "../../components/toast";
import { driverOnlineStatus } from "../../redux/slices/authSlice";
import {
  asyncGetCurrentRide,
  cancelRide,
  setRideDetails,
  updateRideDetails,
  updateCurrentRideActions,
} from "../../redux/slices/rideSlice";
import NoRideView from "./NoRideView";
import RideView from "./RideView";

function DashboardPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const { isOnline, userInfo } = auth;

  const rides = useSelector((state) => state.rides);
  const { currentRide } = rides;

  // useEffect(() => {
  //   if (currentRide?.status === "Completed") {
  //     navigate("/ride-summary");
  //   }
  // }, [currentRide]);

  useEffect(() => {
    dispatch(asyncGetCurrentRide());
  }, []);

  return (
    <div className="px-4 py-2 border-top-0 emp-dashboard">
      <PageSubTitle pageTitle="Dashboard" />

      <div class="container-fluid  m-0 p-0">
        {!currentRide ? <NoRideView /> : <RideView />}
      </div>
    </div>
  );
}

export default DashboardPage;
