import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  InfoWindow,
  InfoWindowF,
  LoadScript,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../constants";
import { useSelector } from "react-redux";
import { memo } from "react";

const containerStyle = {
  width: "100%",
  height: "70vh",
};

// const center = {
//   lat: 0, // Set your initial latitude here
//   lng: 0, // Set your initial longitude here
// };

function MapComponent() {
  const location = useSelector((state) => state.location);

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [openPopup, setOpenPopup] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  // Function to navigate to the current location
  const navigateToCurrentLocation = () => {
    setCenter({ lat: location.coordinates[0], lng: location.coordinates[1] });
    map.zoom = 15;

    // if (map && navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const newCenter = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       };
    //       // setCenter(newCenter);
    //       map.panTo(newCenter);
    //       map.zoom = 15;
    //     },
    //     (error) => {
    //       console.error(error);
    //     }
    //   );
    // }
  };

  useEffect(() => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       setCenter({
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       });
    //       map.zoom = 15;
    //     },
    //     (error) => {
    //       console.error(error);
    //     }
    //   );
    // }

    
    if (isLoaded) {
      setCenter({ lat: location.coordinates[0], lng: location.coordinates[1] });
    }
    // map.zoom = 15;
  }, [isLoaded]);

  // useEffect(() => {
  //   if (location.coordinates) {
  //     setCenter({ lat: location.coordinates[0], lng: location.coordinates[1] });
  //     console.log("loc==>", location);
  //   }
  // }, [location.coordinates]);

  // console.log("map==>", map, center);

  const isLargeScreen = window.innerWidth >= 1024; // Define your breakpoint for large screens
  if (!isLoaded) {
    return null; // Don't render the component until libraries are loaded
  }

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={(map) => setMap(map)}
      options={{
        // scrollwheel: !isLargeScreen, // Disable default scroll zoom on small screens
        gestureHandling: isLargeScreen ? "cooperative" : "greedy", // Enable control + scroll zoom on large screens
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControlOptions: {
          position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
        },
      }}
    >
      {/* {map && ( */}
      {/* <Marker
          position={{ lat: center.lat, lng: center.lng }}
          // icon={{
          //   url: "/assets/images/car-marker.png",
          //   scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
          // }}
        /> */}

      <MarkerF
        position={{
          lat: location.coordinates[0],
          lng: location.coordinates[1],
        }}
        icon={{
          url: "/assets/images/car-marker.png",
          scaledSize: new window.google.maps.Size(50, 50), // Adjust size as needed
        }}
        onMouseOver={() => setOpenPopup(true)}
        onMouseOut={() => setOpenPopup(false)}
        onClick={() => setOpenPopup(true)}
      >
        {openPopup && (
          <InfoWindowF
          // position={{
          //   lat: location.coordinates[0],
          //   lng: location.coordinates[1],
          // }}
          // onCloseClick={() => setOpenPopup(false)}
          >
            <div>
              <h6>You are here!</h6>
            </div>
          </InfoWindowF>
        )}
      </MarkerF>
      {/* {openPopup && ( */}
      {/* <InfoWindow
          position={{
            lat: location.coordinates[0],
            lng: location.coordinates[1],
          }}
          // onCloseClick={() => setOpenPopup(false)}
        >
    
          <div>
            <h6>You are here!</h6>
          </div>
        </InfoWindow> */}
      {/* )} */}
      {/* )} */}
      {/* <Marker
          position={{
            lat: center.lat,
            lng: center.lng,
          }}
        /> */}
      <button
        style={{
          position: "absolute",
          // background: "#198754",
          background: "#fff",
          padding: "8px",
          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)",
          // borderRadius: "5px",
          right: 10,
          top: 10,
          // left: `${selectedMarker.lng + 0.001}deg`,
          // top: `${selectedMarker.lat + 0.001}deg`,
          zIndex: 1000,
        }}
        onClick={navigateToCurrentLocation}
      >
        <img src="/assets/images/my-location.png" width={25} height={25} />
      </button>
    </GoogleMap>
  );
}

export default memo(MapComponent);
