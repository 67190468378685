import React, { useEffect, useState } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   DirectionsService,
//   DirectionsRenderer,
// } from "@react-google-maps/api";
// import { GOOGLE_API_KEY } from "../../constants";
import MapWithRoute from "../../components/mapComponents/mapWithRoute";
import PageSubTitle from "../../components/pageSubTitle";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { asyncGetRideDetails } from "../../redux/slices/rideSlice";
import { formatRideTime } from "../../utils/formatTime";

import RatingComponent from "../../components/RatingComponent";
import CustomButton from "../../components/CustomButton";
import RatingModal from "../../components/modals/ratingModal";
import { notifyFailure, notifySuccess } from "../../components/toast";
import axiosPrivate from "../../services/api";

// const origin = { location: { coordinates: [40.7128, -74.006] } }; // Replace with actual pickup coordinates
// const destination = { location: { coordinates: [39.9526, -75.1652] } }; // Replace with actual drop-off coordinates

const RideDetails = () => {
  const { id } = useParams();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [directions, setDirections] = useState(null);
  const rides = useSelector((state) => state.rides);
  const { rideDetails } = rides;
  const { ride } = rideDetails;

  const [openRatingPopup, setOpenRatingPopup] = useState(false);
  const [rating, setRating] = useState(3);
  const [feedback, setFeedback] = useState("");
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    dispatch(asyncGetRideDetails(id));
    // getDirections();
  }, [dispatch, id]);

  // const getDirections = () => {
  //   const directionsService = new window.google.maps.DirectionsService();

  //   directionsService.route(
  //     {
  //       origin: origin,
  //       destination: destination,
  //       travelMode: "DRIVING",
  //     },
  //     (result, status) => {
  //       if (status === "OK") {
  //         setDirections(result);
  //       } else {
  //         console.error(`Directions request failed with status: ${status}`);
  //       }
  //     }
  //   );
  // };

  // console.log(
  //   "grouped waypoints==>",
  //   groupWaypoints(rideDetails.wayPointsFromPickupToDropoff, 25)
  // );

  console.log("rideDetails=>", rideDetails);
  const handleSubmit = async () => {
    console.log(rideDetails, "dddddddd");
    const ratingPostData = {
      ratingTo: rideDetails.passenger?._id,
      modelNameOfTheRatingToUser: "Passenger",
      ratingBy: rideDetails.driver?._id,
      modelNameOfTheRatingByUser: "Driver",
      ride: rideDetails.ride._id,
      rating: rating,
      feedback: feedback,
    };
    try {
      setIsloading(true);
      await axiosPrivate.post(`/ratings`, ratingPostData);
      setIsloading(false);
      setOpenRatingPopup(false);
      notifySuccess("Rating done successfully!");
      // setRatingDone(true);
      // console.log("Response data:", respones);
      // console.log(isloading);
    } catch (error) {
      notifyFailure(error.response.data.message);
      setIsloading(false);
      // Handle any errors that occur during the request
      console.error("Error:", error);
    }

    // Call your API to submit the rating
    // handleRatingSubmit({
    //   ratingTo: ratingTo, // Replace with the appropriate user ID
    //   rating: rating,
    //   feedback: feedback,
    // });
    // handleClose(); // Close the modal
  };
  return (
    <div className="px-4 py-2 border-top-0 emp-dashboard">
      <PageSubTitle pageTitle="Ride Details" />
      {ride ? (
        <div class="container-fluid  m-0 p-0">
          <div className="card">
            {/* <div className="card-header">
            <h3 className="text-center">Ride Summary</h3>
          </div> */}
            <div className="card-body">
              <h6 className="card-title py-3">
                {rideDetails.ride.status
                  ? `The ride is ${rideDetails.ride.status}`
                  : ""}{" "}
                {rideDetails.ride.status === "Active" && (
                  <>
                    and{" "}
                    {rideDetails.ride.route.rideStarted
                      ? "is in progress."
                      : rideDetails.ride.route.passengerPicked
                      ? "the passenger is picked."
                      : rideDetails.ride.route.reachedAtPickup
                      ? "the driver has reacthed to the pickup!"
                      : ""}
                  </>
                  // rideDetails.ride.route.reachedAtPickup
                  // ? "The driver has reacthed to the pickup!"
                )}
              </h6>
              {/* <p className="card-text">Your trip has ended.</p> */}

              <MapWithRoute
                startLocation={
                  rideDetails.driverWayPointsToPickup[0]
                    ? rideDetails.driverWayPointsToPickup[0].coordinates
                        .slice()
                        .reverse()
                    : []
                }
                pickupLocation={
                  rideDetails?.wayPointsFromPickupToDropoff[0]
                    ? rideDetails?.wayPointsFromPickupToDropoff[0]?.coordinates
                        .slice()
                        .reverse()
                    : ride?.route?.pickup?.location?.coordinates
                        .slice()
                        .reverse()
                }
                dropoffLocation={
                  rideDetails.wayPointsFromPickupToDropoff[
                    rideDetails?.wayPointsFromPickupToDropoff?.length - 1
                  ]
                    ? rideDetails?.wayPointsFromPickupToDropoff[
                        rideDetails?.wayPointsFromPickupToDropoff?.length - 1
                      ]?.coordinates
                        .slice()
                        .reverse()
                    : ride?.route?.dropoff?.location?.coordinates
                        .slice()
                        .reverse()
                }
                directions={directions}
                setDirections={setDirections}
                rideWaypoints={rideDetails?.wayPointsFromPickupToDropoff}
                waypointsToPickup={rideDetails?.driverWayPointsToPickup}
                // waypoints2={[
                //   { lat: 37.7749, lng: -122.4194 },
                //   { lat: 37.8049, lng: -122.4302 },
                // ]}
              />

              {/* <MapComponentWithDirections
                pickup={origin}
                dropoff={destination}
                directions={directions}
                setDirections={setDirections}
                waypoints={rideDetails.wayPointsFromPickupToDropoff}
                waypoints2={[
                  { lat: 37.7749, lng: -122.4194 },
                  { lat: 37.8049, lng: -122.4302 },
                ]}
              /> */}
              {/* <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
                  {" "}
                  <GoogleMap center={origin} zoom={10}>
                    <Marker position={origin} label="Pickup" />
                    <Marker position={destination} label="Drop-off" />
                    {directions && (
                      <DirectionsRenderer directions={directions} />
                    )}
                  </GoogleMap>
                </LoadScript> */}

              <div class="row py-3">
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Passenger:</strong> {ride?.passenger?.firstName}{" "}
                    {ride?.passenger?.lastName}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Booking Time:</strong>{" "}
                    {moment(ride.bookingTime).format("DD MMM, YYYY @ hh:mm a")}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Estimated Pickup:</strong>{" "}
                    {ride.rideRequest?.pickup?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Estimated Dropoff:</strong>{" "}
                    {ride.rideRequest?.dropoff?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Actual Pickup:</strong>{" "}
                    {ride.route?.pickup?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}                                
                    <strong>Actual Dropoff:</strong>{" "}
                    {ride.route?.pickup?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Arrival Time:</strong>{" "}        
                    {ride.route.reachedAtPickup
                      ? moment(
                          ride.rideActions.find(
                            (x) => x.type === "reachedAtPickup"
                          )?.timestamp
                        ).format("DD MMM, YYYY @ hh:mm a")
                      : "N/A"}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">            
                    {" "}
                    <strong>Pickup Time:</strong>{" "}
                    {ride.route.passengerPicked
                      ? moment(
                          ride.rideActions.find(       
                            (x) => x.type === "passengerPicked"
                          )?.timestamp
                        ).format("DD MMM, YYYY @ hh:mm a")
                      : "N/A"}         
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">                    
                    {" "}
                    <strong>Dropoff Time:</strong>{" "}
                    {ride.route.rideEnded
                      ? moment(                    
                          ride.rideActions.find((x) => x.type === "rideEnded")
                            ?.timestamp
                        ).format("DD MMM, YYYY @ hh:mm a")
                      : "N/A"}
                  </div>                   
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">                 
                    {" "}                          
                    <strong>Vehicle:</strong> {ride.vehicle?.color}{" "}
                    {ride.vehicle?.make} {ride.vehicle?.model}{" "}
                    {ride.vehicle?.year}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">           
                    {" "}
                    <strong>Waiting Time:</strong>{" "}
                    {formatRideTime(ride.route.waitingTimeInMinutes * 60)}
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">                 
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Ride Time:</strong>{" "}
                    {formatRideTime(         
                      ride.route.pickupToDropoffTimeInMinutes * 60
                    )}
                  </div>
                </div>{" "}                  
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Distance till Pickup:</strong>{" "}
                    {ride.route.distanceFromStartToPickup} km
                  </div>                       
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}                 
                    <strong>
                      Ride Distance {`( Pickup to Dropoff )`}:                              
                    </strong>{" "}
                    {ride.route.distanceFromPickupToDropoff} km
                  </div>
                </div>{" "}
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}               
                    <strong>Fare:</strong> {ride.route.fareDetails?.currency}{" "}
                    {ride.route.fareDetails?.totalFare}{" "}
                    {`( ${ride.route.fareDetails?.fareWithoutCommission} without commission )`}
                  </div>
                </div>
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">
                    {" "}
                    <strong>Rating by passenger:</strong>{" "}         
                    {rideDetails.ride.ratingDetails.passengerRating ? (
                      <div className="mt-2">
                        <RatingComponent
                          rating={
                            rideDetails.ride.ratingDetails.passengerRating
                              .rating               
                          }
                          iconSize="28px"
                          readOnly
                        />
                        {rideDetails.ride.ratingDetails.passengerRating                     
                          .feedback !== "" && (       
                          <p className="mt-2">           
                            <strong>Comment:</strong>{" "}
                            {            
                              rideDetails.ride.ratingDetails.passengerRating
                                .feedback
                            }
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="mt-3">N/A</div>
                    )}
                  </div>
                </div>
                <div class="col-12 col-md-4 p-2">
                  <div class="p-3 card h-100">                    
                    {" "}
                    <strong>Your rating to passenger:</strong>{" "}
                    {rideDetails.ride.ratingDetails.driverRating ? (
                      <div className="mt-2">
                        <RatingComponent                       
                          rating={
                            rideDetails.ride.ratingDetails.driverRating.rating
                          }
                          iconSize="28px"
                          readOnly
                        />
                        <p className="mt-2">
                          <strong>Comment:</strong>{" "}                 
                          {rideDetails.ride.ratingDetails.driverRating.feedback}
                        </p>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <CustomButton
                          // type="submit"
                          // disabled={isLoading}
                          // isLoading={isLoading}      
                          onClick={() => setOpenRatingPopup(true)}
                          title={"Rate Now"}
                          className={"btn btn-primary"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>                               
            </div>
            {/* Other ride details */}                       
            {/* <div className="card-footer text-muted text-center">
              <p>Thank you for choosing our service!</p>
            </div> */}
          </div>
          {openRatingPopup && (
            <>
              <RatingModal
                show={openRatingPopup}
                handleSubmit={handleSubmit}
                rating={rating}
                handleClose={() => setOpenRatingPopup(false)}
                setRating={setRating}             
                feedback={feedback}             
                setFeedback={setFeedback}
                isLoading={isloading}
                // isSubmitLoading={isSubmitLoading}
              />
            </>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default RideDetails;
