import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";
import { GOOGLE_API_KEY } from "../../constants";

export const asyncGetUserLocation = createAsyncThunk(
  "getUserLocation",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPublic.get(
        `/locations/getByLatLng?lat=${obj.latitude}&lng=${obj.longitude}&apiKey=${GOOGLE_API_KEY}`
      );

      const addressComponents = response.data.results[0].address_components;
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      const country = addressComponents.find((component) =>
        component.types.includes("country")
      );

      // setLocation(`${city.long_name}, ${country.long_name}`);

      return fulfillWithValue({ city, country });
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const cabSlice = createSlice({
  name: "userLocation",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    country: null,
    city: null,
    coordinates: [0, 0],
    hasLocationPermission: false,
    isError: false,
  },
  extraReducers: (builder) => {
    // Cases for Get Cabs

    builder.addCase(asyncGetUserLocation.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetUserLocation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.city = action.payload.city;
      state.country = action.payload.country;
      state.hasLocationPermission = true;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetUserLocation.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
  reducers: {
    hasLocationPermission(state, action) {
      state.hasLocationPermission = action.payload;
    },
    updateUserLocation(state, action) {
      state.coordinates = action.payload;
    },
  },
});

export const { hasLocationPermission, updateUserLocation } = cabSlice.actions;
export default cabSlice.reducer;
