import { io } from "socket.io-client";
// import { BASE_URL } from "./api";
import { SOCKET_BASE_URL } from "../constants";
// import handleIDB from '../utils/handle-indexed-db'

// const driverId = localStorage.getItem("cabtifyDriverInfo")
//   ? JSON.parse(localStorage.getItem("cabtifyDriverInfo"))._id
//   : "";

const socket = io(SOCKET_BASE_URL, {
  transports: ["polling"],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 10000,
  reconnectionAttempts: Infinity,
  // reconnectionAttempts: 1,
});

export default socket;
