import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetDriverProfile = createAsyncThunk(
  "getDriverProfile",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    console.log(obj);
    const { id } = obj;
    try {
      const response = await axiosPrivate.get(`/drivers/get-profile`);

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const profileSlice = createSlice({
  name: "profiles",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
    driverProfile: { isLoading: false, data: {}, isError: false },
  },
  extraReducers: (builder) => {
    // Cases for Get Driver Profile
    builder.addCase(asyncGetDriverProfile.pending, (state, action) => {
      state.driverProfile.isLoading = true;
    });
    builder.addCase(asyncGetDriverProfile.fulfilled, (state, action) => {
      state.driverProfile.isLoading = false;
      state.driverProfile.data = action.payload;
      state.driverProfile.isError = false;
      state.driverProfile.message = null;
    });
    builder.addCase(asyncGetDriverProfile.rejected, (state, action) => {
      // console.log("Error", action);
      state.driverProfile.isLoading = false;
      state.driverProfile.message = action.payload;
      state.driverProfile.isError = true;
    });
  },
  reducers: {},
});

export const {
  // userLogout
} = profileSlice.actions;
export default profileSlice.reducer;
