import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import Logo from "../../assets/images/logo-black-b.png";

function Sidebar() {
  const dispatch = useDispatch(null);
  const sidebarRef = useRef(null);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const toggleSidebar = (e) => {
    document.body.classList.toggle("sidebar-main");
    // You can add more logic here if needed
  };

  const scrollSidebar = (scrollAmount) => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTop += scrollAmount;
    }
  };

  useEffect(() => {
    // Add event listeners to control scrolling
    const handleWheel = (event) => {
      // Adjust the scroll amount as needed (negative for scroll up, positive for scroll down)
      const scrollAmount = event.deltaY;

      // Prevent the default scroll behavior
      event.preventDefault();

      // Scroll the sidebar
      scrollSidebar(scrollAmount);
    };

    // Attach the event listener to the sidebar element
    if (sidebarRef.current) {
      sidebarRef.current.addEventListener("wheel", handleWheel);

      // Use a timeout to check for overflow after a slight delay
      setTimeout(() => {
        setShowScrollbar(
          sidebarRef.current.scrollHeight > sidebarRef.current.clientHeight
        );
      }, 100); // Adjust the timeout duration as needed
    }

    return () => {
      // Remove the event listener when the component unmounts
      if (sidebarRef.current) {
        sidebarRef.current.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);
  // console.log(
  //   "scrolls==>",
  //   sidebarRef.current.scrollHeight,
  //   sidebarRef.current.clientHeight
  // );

  const logoutHandler = () => {
    dispatch(logout());
  };
  return (
    <div className="mm-sidebar sidebar-default">
      <div className="mm-sidebar-logo d-flex align-items-center justify-content-between">
        <a href="/" className="header-logo">
          <img
            src={Logo}
            className="img-fluid mode light-img rounded-normal light-logo site_logo_preview"
            alt="logo"
          />
          <img
            src={Logo}
            className="img-fluid mode dark-img rounded-normal  darkmode-logo site_dark_logo_preview"
            alt="dark-logo"
          />
        </a>
        <div className="side-menu-bt-sidebar" onClick={toggleSidebar}>
          <i className="fas fa-bars wrapper-menu" />
        </div>
      </div>
      <div
        ref={sidebarRef} // Reference to the sidebar element
        className="data-scrollbar  scrollbar-macosx"
        data-scroll={1}               
        data-scrollbar="true"
        tabIndex={-1}
        style={{ overflow: "hidden", outline: "none", background: "#353535" }}
      >                            
        <div className="scroll-content">
          <nav className="mm-sidebar-menu">
            <ul id="mm-sidebar-toggle" className="side-menu">
              <li className="active">
                <NavLink          
                  className="nav-link"
                  activeClassName="active"                                             
                  to="/dashboard"
                >    
                  <i className="fas fa-home" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li className="active">
                <NavLink             
                  className="nav-link"
                  activeClassName="active"
                  to="/sattlements"
                >
                  <i className="fas fa-wallet" />               
                  <span>Daily Sattlements</span>
                </NavLink>
              </li>                  
              <li className="active">
                <NavLink
                  className="nav-link"
                  activeClassName="active"            
                  to="/rides"
                >
                  <i className="fas fa-route" />
                  <span>Rides</span>
                </NavLink>                                                        
              </li>{" "}
              <li className="active">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/ride-requests"
                >
                  <i className="fas fa-paper-plane" />
                  <span>Ride Requests</span>
                </NavLink>
              </li>{" "}             
              <li className="active">
                <NavLink
                  className="nav-link"
                  activeClassName="active"                         
                  to="/wallet"
                >
                  <i className="fas fa-wallet" />
                  <span>Wallet</span>
                </NavLink>
              </li>                           
              <li className="active">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/documents"                                            
                >
                  <i className="fas fa-file" />
                  <span>Documents</span>                                      
                </NavLink>     
              </li>
              {/* <li className="">             
                <a
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  className=""
                  href="#document"
                >
                  <i className="fas fa-file" />
                  <span>Document</span>
                  <i className="fas fa-angle-right mm-arrow-right arrow-active" />
                  <i className="fas fa-angle-down mm-arrow-right arrow-hover" />
                </a>
                <ul className="submenu collapse  " id="document">
                  <li className="sidebar-layout">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/documents"
                    >
                      <i className="fas fa-list" />
                      <span>Documents List</span>
                    </NavLink>
                  </li>

                  <li className="sidebar-layout">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/cab-documents"
                    >
                      <i className="fas fa-list" />
                      <span>Cab Documents</span>
                    </NavLink>
                  </li>
                </ul>
              </li> */}
              <li className="accordionItem closeIt">
                <NavLink
                  className="nav-item text-lightest f-15 sidebar-text-color"
                  to=""
                  onClick={logoutHandler}
                >
                  <i class="fas fa-sign-out-alt"></i>                       
                  <span className="pl-3">Logout</span>
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="pt-5 pb-2" />
        </div>
        <div className="scrollbar-track scrollbar-track-x" />
        <div                      
          className={`scrollbar-track ${
            showScrollbar ? "show" : ""                 
          } scrollbar-track-y`}
        />
      </div>
    </div>
  );
}

export default Sidebar;
