import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetRideRequests = createAsyncThunk(
  "getRides",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/rideRequests/driver");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetRideRequestDetails = createAsyncThunk(
  "getRideRequestDetails",
  async (id, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/rideRequests/${id}`);

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const rideSlice = createSlice({
  name: "rideRequests",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
    rideRequestDetails: null,
  },
  extraReducers: (builder) => {
    // Cases for Get Rides

    builder.addCase(asyncGetRideRequests.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRideRequests.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRideRequests.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for ride details
    builder.addCase(asyncGetRideRequestDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRideRequestDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rideRequestDetails = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRideRequestDetails.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
  reducers: {
    // userLogout(state, action) {
    //   state.userInfo = null;
    // },
    addRideRequest(state, action) {
      state.list = [action.payload, ...state.list];
    },
  },
});

export const { addRideRequest } = rideSlice.actions;
export default rideSlice.reducer;
