import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetCompanySettings = createAsyncThunk(
  "asyncGetCompanySettings",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/settings");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }          
);

const companySettingSlice = createSlice({
  name: "companySettings",
  initialState: {
    isLoading: false,
    isError: false,    
    data: {},
    update: {},     
  },

  extraReducers: (builder) => {
    builder.addCase(asyncGetCompanySettings.pending, (state, action) => {
      state.isLoading = true;
    });            
    builder.addCase(asyncGetCompanySettings.fulfilled, (state, action) => {
      state.isLoading = false;      
      state.data = action.payload;
      state.isError = false;        
      state.message = null;
    });                   
    builder.addCase(asyncGetCompanySettings.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;            
    });
  },
});

// export const {} = companySettingSlice.actions;
export default companySettingSlice.reducer;
