import React, { useState } from "react";
// import { useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "../../components/table";
import moment from "moment";
// import { asyncGetRides } from "../../redux/slices/rideSlice";
// import { Link } from "react-router-dom";
// import moment from "moment/moment";
// import { formatRideTime } from "../../utils/formatTime";
// import { asyncGetDailySattlements } from "../../redux/slices/SattlementSlice";
// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const UnclearSattlementsPaymentsTable = () => {
  const auth = useSelector((state) => state.auth);
  const dailySattlements = useSelector((state) => state.dailySattlements);

  const { userInfo } = auth.userInfo;
  const { country } = auth.userInfo || {};

  const { data, isLoading } = dailySattlements;
  // console.log(data?.paymentsList, "datapaymentsList");
  // const auth = useSelector((state) => state.auth);
  // const rides = useSelector((state) => state.rides);

  // const { isLoading, list } = rides;
  // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  // const openMoreVertDropDown = () => {
  //   setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  // };

  // const [modal, setModal] = useState({ open: false, data: null });

  // const handleModal = (data) => {
  //   setModal({ open: !modal.open, data: data });
  // };

  // const dispatch = useDispatch();
  // const [currentPageNumber, setCurrentPageNumber] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= users.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  const columns = [
    {
      displayName: "Payment Received On",
      displayField: (e) => {
        return <>{moment(e?.date).format("MMM DD, YYYY")}</>;
      },
      searchable: true,
    },
    {
      displayName: "Total Fare",
      displayField: (e) => (
        <>
          {country?.currency} {e?.totalFare}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Cash Collected",
      displayField: (e) => (
        <>
          {country?.currency} {e?.cashCollected}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Wallet Payment",
      displayField: (e) => (
        <>
          {country?.currency} {e?.walletPayment}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Commission",
      displayField: (e) => (
        <>
          {country?.currency} {e?.commission}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Earnings",
      displayField: (e) => (
        <>
          {country?.currency} {e?.earning}
        </>
      ),
      searchable: true,
    },
  ];
  return (
    <>
      <Table
        dataSource={data?.unclearSettlementsPaymentsList.slice().reverse()}
        isLoading={isLoading}
        columns={columns}
      />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};

export default UnclearSattlementsPaymentsTable;
