import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncSendOtp = createAsyncThunk(
  "sendOtp",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPublic.post("/drivers/send-otp", obj);
      toast.success("OTP sent successfully!");

      return fulfillWithValue({ ...response.data, ...obj });
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncResendOtp = createAsyncThunk(
  "resendOtp",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPublic.post("/drivers/send-otp", {
        phone: getState().auth.phone,
      });
      toast.success("OTP resent successfully!");

      return fulfillWithValue({ ...response.data, ...obj });
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncVerifyOtp = createAsyncThunk(
  "verifyOtp",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPublic.post("/drivers/verify-otp", obj);
      toast.success("OTP verified successfully!");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncSubmitPersonalData = createAsyncThunk(
  "submitPersonalData",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback } = payload;
    const driverId = getState().auth.userInfo._id;
    try {
      const response = await axiosPrivate.post(
        `/drivers/submit-personal-details`,
        data
      );
      // toast.success("Personal details submitted successfully!");

      if (typeof callback === "function") {
        callback(data);
      }

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncSubmitVehicleData = createAsyncThunk(
  "submitVehicleData",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback } = payload;
    // const driverId = getState().auth.userInfo._id;
    try {
      const response = await axiosPrivate.post(
        `/drivers/submit-vehicle-details`,
        {
          ...data,
          // driver: driverId,
        }
      );
      // toast.success("Vehicles details submitted successfully!");

      if (typeof callback === "function") {
        callback(data);
      }

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncSubmitDocuments = createAsyncThunk(
  "submitDocuments",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback } = payload;
    const driverId = getState().auth.userInfo._id;
    try {
      const response = await axiosPrivate.post(
        `/drivers/submit-all-documents`,
        data
      );
      // toast.success("Personal details submitted successfully!");

      if (typeof callback === "function") {
        callback();
      }

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    tokens: localStorage.getItem("cabtifyDriverTokens")
      ? JSON.parse(localStorage.getItem("cabtifyDriverTokens"))
      : null,
    newRegister: false,
    userInfo: localStorage.getItem("cabtifyDriverInfo")
      ? JSON.parse(localStorage.getItem("cabtifyDriverInfo"))
      : null,
    vehicleInfo: localStorage.getItem("cabtifyDriverVehicleInfo")
      ? JSON.parse(localStorage.getItem("cabtifyDriverVehicleInfo"))
      : null,
    isError: false,
    isOnline: false,
  },
  extraReducers: (builder) => {
    // Send OTP cases
    builder.addCase(asyncSendOtp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncSendOtp.fulfilled, (state, action) => {
      // localStorage.setItem(
      //   "cabtifyTokens",
      //   JSON.stringify(action.payload.tokens)
      // );
      state.isLoading = false;
      state.otpSent = true;
      state.phone = action.payload.phone;
      // state.userInfo = action.payload.user;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncSendOtp.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.otpSent = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Resend OTP cases
    builder.addCase(asyncResendOtp.pending, (state, action) => {
      state.isLoading = true;
      state.otpResent = false;
    });
    builder.addCase(asyncResendOtp.fulfilled, (state, action) => {
      // localStorage.setItem(
      //   "cabtifyTokens",
      //   JSON.stringify(action.payload.tokens)
      // );
      state.isLoading = false;
      state.otpResent = true;
      // state.phone = action.payload.phone;
      // state.userInfo = action.payload.user;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncResendOtp.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.otpSent = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Verify OTP Cases
    builder.addCase(asyncVerifyOtp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncVerifyOtp.fulfilled, (state, action) => {
      // if (!action.payload.newRegister) {
      if (action.payload.tokens)
        localStorage.setItem(
          "cabtifyDriverTokens",
          JSON.stringify(action.payload.tokens)
        );
      if (action.payload.driver)
        localStorage.setItem(
          "cabtifyDriverInfo",
          JSON.stringify(action.payload.driver)
        );
      if (action.payload.vehicle)
        localStorage.setItem(
          "cabtifyDriverVehicleInfo",
          JSON.stringify(action.payload.vehicle)
        );
      // }
      state.isLoading = false;
      state.otpSent = true;
      state.otpVerified = true;
      state.phone = action.payload.phone;
      state.userInfo = action.payload.driver;
      state.tokens = action.payload.tokens;
      state.newRegister = action.payload.newRegister;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncVerifyOtp.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.otpSent = true;
      state.otpVerified = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Complete Registeration Cases
    // Personal data
    builder.addCase(asyncSubmitPersonalData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncSubmitPersonalData.fulfilled, (state, action) => {
      // if (action.payload.tokens)
      //   localStorage.setItem(
      //     "cabtifyDriverTokens",
      //     JSON.stringify(action.payload.tokens)
      //   );

      if (action.payload.driver)
        localStorage.setItem(
          "cabtifyDriverInfo",
          JSON.stringify(action.payload)
        );

      state.isLoading = false;
      // state.otpSent = true;
      // state.otpVerified = true;
      // state.phone = action.payload.phone;
      state.userInfo = action.payload;
      // state.tokens = action.payload.tokens;
      // state.registerationCompleted = true;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncSubmitPersonalData.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      // state.otpSent = true;
      // state.otpVerified = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Complete Registeration Cases
    // Vehicle details
    builder.addCase(asyncSubmitVehicleData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncSubmitVehicleData.fulfilled, (state, action) => {
      // if (action.payload.tokens)
      // //   localStorage.setItem(
      // //     "cabtifyDriverTokens",
      // //     JSON.stringify(action.payload.tokens)
      // //   );

      // if (action.payload.driver)
      //   localStorage.setItem(
      //     "cabtifyDriverInfo",
      //     JSON.stringify(action.payload)
      //   );

      state.isLoading = false;
      // // state.otpSent = true;
      // // state.otpVerified = true;
      // // state.phone = action.payload.phone;
      state.userInfo = { ...state.userInfo, vehicleDetailsSubmitted: true };
      state.vehicleInfo = action.payload;
      localStorage.setItem(
        "cabtifyDriverInfo",
        JSON.stringify({ ...state.userInfo, vehicleDetailsSubmitted: true })
      );

      // // state.tokens = action.payload.tokens;
      // // state.registerationCompleted = true;
      // state.isError = false;
      // state.message = null;
    });
    builder.addCase(asyncSubmitVehicleData.rejected, (state, action) => {
      // // console.log("Error", action);
      state.isLoading = false;
      // // state.otpSent = true;
      // // state.otpVerified = false;
      // state.message = action.payload;
      // state.isError = true;
    });
  },
  reducers: {
    changeNumber(state, action) {
      state.otpSent = false;
      state.otpResent = false;
      state.phone = "";
    },
    logout(state, action) {
      localStorage.removeItem("cabtifyDriverTokens");
      localStorage.removeItem("cabtifyDriverInfo");
      localStorage.removeItem("cabtifyDriverVehicleInfo");

      state.otpSent = false;
      state.otpResent = false;
      state.userInfo = null;
      state.tokens = null;
    },
    driverOnlineStatus(state, action) {
      state.isOnline = action.payload;
    },
    updateUserInfo(state, action) {
      state.userInfo = action.payload;
      localStorage.setItem("cabtifyDriverInfo", JSON.stringify(action.payload));
    },
  },
});

export const { changeNumber, logout, driverOnlineStatus, updateUserInfo } =
  authSlice.actions;
export default authSlice.reducer;
