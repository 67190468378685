import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function PublicLayout({ children }) {
  return (
    <div className="full-height-cont">
      <Header />
      <div className="h-100 ">{children}</div>
      {/* <Footer /> */}
    </div>
  );
}

export default PublicLayout;
