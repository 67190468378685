import React, { useEffect, useState } from "react";

import { uploadMultipleFiles, uploadSingleFile } from "../services/upload";

function Test() {
  const [files, setFiles] = useState(null);
  const [progress, setProgress] = useState([]);
  const [uploadComplete, setUploadComplete] = useState([]);

  const fileHandler = (e) => {
    setFiles(e.target.files);
  };

  const submitFile = async () => {
    const url = await uploadMultipleFiles({
      files,
      directory: `documents/${"driverId"}/`,
      progressHandler: setProgress,
      uploadCompleteHandler: setUploadComplete,
    });

    console.log("url==>", url);
  };
  console.log("progress=>", progress, uploadComplete);

  // function switchHeadlines() {
  //   const marquee = document.getElementById("marquee");
  //   const headlines = marquee.children;

  //   // Move the first headline to the end
  //   marquee.appendChild(headlines[0].cloneNode(true));
  //   marquee.removeChild(headlines[0]);
  // }

  // Set interval to switch headlines every 10 seconds

  useEffect(() => {
    let currentHeadline = 0;
    const marquee = document.getElementById("marquee");
    const headlines = marquee.children;
    headlines[currentHeadline + 1].style.display = "none";
    headlines[currentHeadline + 2].style.display = "none";

    function switchHeadline() {
      headlines[currentHeadline].style.display = "none";
      currentHeadline = (currentHeadline + 1) % headlines.length;
      headlines[currentHeadline].style.display = "block";
    }

    setInterval(switchHeadline, 20000);
  }, []);

  return (
    <>
      {/* <input type="file" multiple onChange={fileHandler} />
      <button onClick={submitFile}>Submit</button> */}
      <div class="marquee-container">
        <div class="marquee" id="marquee">
          <span>Thanksgiving Offers are now live! </span>
          <span>Thanksgiving Offers are 2 now live!</span>
          <span>Thanksgiving Offers are 3 now live! </span>
        </div>
      </div>
    </>
  );
}

export default Test;
