function formatRideTime(seconds) {
  console.log("seconds==>", seconds);
  if (seconds < 60) {
    return `${Math.round(seconds)}s`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes}m ${
      seconds % 60 !== 0 ? Math.round(seconds % 60) + "s" : ""
    }`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${hours !== 1 ? "s" : ""} ${remainingMinutes}m ${
      remainingMinutes !== 1 ? "s" : ""
    }`;
  }
}

module.exports = {
  formatRideTime,
};
