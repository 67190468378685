// uploadService.js
import AWS from "aws-sdk";

import {
  BUCKET_ACCESS_KEY_ID,
  BUCKET_ENDPOINT,
  BUCKET_NAME,
  BUCKET_PUBLIC_URL,
  BUCKET_REGION,
  BUCKET_SECRET_KEY,
} from "../constants";

async function uploadSingleFile({
  file,
  directory,
  progressHandler,
  uploadCompleteHandler,
}) {
  if (!file) {
    return;
  }

  const s3 = new AWS.S3({
    region: BUCKET_REGION,
    endpoint: BUCKET_ENDPOINT,
    credentials: {
      accessKeyId: BUCKET_ACCESS_KEY_ID,
      secretAccessKey: BUCKET_SECRET_KEY,
    },
  });

  const today = new Date();
  const tsForUnique = today.getTime();
  //   const { name } = req.body;
  const filePath = directory + tsForUnique + "/" + file.name;

  const params = {
    Bucket: BUCKET_NAME,
    Key: filePath,
    Body: file,

    // ACL: 'public-read',
  };

  await s3
    .upload(params)
    .on("httpUploadProgress", (evt) => {
      progressHandler(Math.round((evt.loaded / evt.total) * 100));
      //   console.log("pro==>", Math.round((evt.loaded / evt.total) * 100));
    })
    .promise();
  // let url="";
  // await multipartUpload();
  const url = filePath;
  // console.log(url);

  progressHandler(null);
  uploadCompleteHandler(true);

  return url;
}

async function uploadMultipleFiles({
  files,
  directory,
  progressHandler,
  uploadCompleteHandler,
}) {
  const s3 = new AWS.S3({
    region: BUCKET_REGION,
    endpoint: BUCKET_ENDPOINT,
    credentials: {
      accessKeyId: BUCKET_ACCESS_KEY_ID,
      secretAccessKey: BUCKET_SECRET_KEY,
    },
  });

  const urls = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    // const progressHandler = progressHandler[i];
    // const uploadCompleteHandler = uploadCompleteHandler[i];

    const today = new Date();
    const tsForUnique = today.getTime();
    const filePath = directory + tsForUnique + "/" + file.name;

    const params = {
      Bucket: BUCKET_NAME,
      Key: filePath,
      Body: file,
    };

    try {
      progressHandler((prev) => [...prev, 0]);
      await s3
        .upload(params)
        .on("httpUploadProgress", (evt) => {
          progressHandler((prev) =>
            prev.map((x, index) =>
              i == index ? Math.round((evt.loaded / evt.total) * 100) : x
            )
          );
        })
        .promise();

      const url = BUCKET_PUBLIC_URL + filePath;
      urls.push(url);

      // progressHandler(null);
      uploadCompleteHandler((prev) => [...prev, true]);
    } catch (error) {
      console.error("An error occurred during file upload:", error);
      progressHandler(null);
      uploadCompleteHandler(false);
    }
  }

  return urls;
}

export { uploadSingleFile, uploadMultipleFiles };
