import React, { useEffect, useState } from "react";
import { asyncGetVehicleCategories } from "../../redux/slices/vehicleCategorySlice";
import { useDispatch, useSelector } from "react-redux";

const VehicleDetailsForm = ({ vehicleData, setVehicleData }) => {
  // const [vehicleData, setVehicleData] = useState({
  //   make: "",
  //   model: "",
  //   year: "",
  //   color: "#ffffff",
  //   plateNumber: "",
  // });
  const dispatch = useDispatch();
  const vehicleCategories = useSelector((state) => state.vehicleCategories);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehicleData({
      ...vehicleData,
      [name]: value,
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // You can handle form submission here, e.g., send data to the server
  //   console.log(vehicleData);
  // };

  useEffect(() => {
    dispatch(asyncGetVehicleCategories());
  }, []);

  return (
    <div className="container mt-4">
      <h2>Vehicle Details Form</h2>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="make" className="form-label">
            Make
          </label>
          <input
            type="text"
            className="form-control"
            id="make"
            name="make"
            value={vehicleData.make}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="model" className="form-label">
            Model
          </label>
          <input
            type="text"
            className="form-control"
            id="model"
            name="model"
            value={vehicleData.model}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="year" className="form-label">
            Year
          </label>
          <input
            type="number"
            className="form-control"
            id="year"
            name="year"
            value={vehicleData.year}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="color" className="form-label">
            Color
          </label>

          <input
            type="text"
            id="color"
            name="color"
            onChange={handleChange}
            value={vehicleData.color}
            required
          />

          {/* <input
            type="color"
            className="form-control"
            id="color"
            name="color"
            value={vehicleData.color}
            onChange={handleChange}
          /> */}
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="plateNumber" className="form-label">
            License Plate
          </label>
          <input
            type="text"
            className="form-control"
            id="plateNumber"
            name="plateNumber"
            value={vehicleData.plateNumber}
            onChange={handleChange}
          />
        </div>

        {/* <div className="col-12 col-md-6 mb-3">
          <label htmlFor="engineCC" className="form-label">
            Engine CC
          </label>
          <input
            type="number"
            className="form-control"
            id="engineCC"
            name="engineCC"
            value={vehicleData.engineCC}
            onChange={handleChange}
          />
        </div> */}

        {/* <div className="col-12 col-md-6 mb-3">
          <label htmlFor="category" className="form-label">
            Vehicle Category
          </label>
          <select
            type="number"
            className="form-control"
            id="category"
            name="category"
            value={vehicleData.category}
            onChange={handleChange}
          >
            <option default>Select Vehicle Category</option>
            {vehicleCategories.list.map((x) => (
              <option value={x._id}>
                {x.name} {`( ${x.seatingSpace} Person(s) )`}
              </option>
            ))}
          </select>
        </div> */}
      </div>
      {/* <button type="submit" className="btn btn-primary">
          Submit
        </button> */}
      {/* </form> */}
    </div>
  );
};

export default VehicleDetailsForm;
