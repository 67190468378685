import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import CustomButton from "../CustomButton";
import { useSelector } from "react-redux";
import { BUCKET_PUBLIC_URL } from "../../constants";

function RideCompleteModal({
  show,
  payWithWalletHandler,
  payWithCashHandler,
  // isSubmitLoading,
  // submitHandler,
}) {
  const rides = useSelector((state) => state.rides);
  const { currentRide } = rides;
  const [timeLeft, setTimeLeft] = useState(30); // Initial time left in seconds

  return (
    <Modal show={show} centered>
      <div className="small-modal">
        <Modal.Header className="d-flex justify-content-between">
          <h5>Ride Completed</h5>
          {/* <h6 className="text-danger">{formattedTimeLeft}</h6> */}
        </Modal.Header>
        <Modal.Body>
          {/* Passenger Information */}
          <div className="mb-3">
            <h6>Passenger Information:</h6>
            <div className="d-flex align-items-center">
              <img
                src={
                  BUCKET_PUBLIC_URL + currentRide?.passenger?.profileImageUrl
                }
                width={100}
                alt="Passenger"
                className="mr-3 rounded-circle"
              />
              <div>
                <p className="mb-0">
                  <span className="fw-bold">Name:</span>{" "}
                  {currentRide.passenger.firstName}{" "}
                  {currentRide.passenger.lastName}
                </p>
                {/* <p className="mb-0">Email: john@example.com</p> */}
                <p className="mb-0">
                  <span className="fw-bold">Phone:</span>{" "}
                  {currentRide.passenger.phone}
                </p>
              </div>
            </div>
          </div>

          {/* Pickup and Dropoff Locations */}
          <div className="mb-3">
            <h6>Pickup Location:</h6>
            <div className="d-flex align-items-start">
              <img
                src={"/assets/images/location-icon.png"}
                width={20}
                alt="Location"
                className="mr-2 mt-1"
              />
              <p className="mb-0">
                {currentRide?.route?.pickup?.address?.formattedAddress}
              </p>
            </div>
          </div>
          <div className="mb-3">
            <h6>Dropoff Location:</h6>
            <div className="d-flex align-items-center">
              <img
                src={"/assets/images/location-icon.png"}
                width={20}
                alt="Location"
                className="mr-2 mt-1"
              />
              <p className="mb-0">
                {currentRide?.route?.dropoff?.address?.formattedAddress}
              </p>
            </div>
          </div>

          <div>
            <h6>Distance / Duration:</h6>
            <p>
              {currentRide?.route?.distanceFromPickupToDropoff} km /{" "}
              {currentRide?.route?.pickupToDropoffTimeInMinutes} mins
            </p>
          </div>
          {/* Fare and Distance */}
          <div className="mb-3">
            <h6>Ride Fare:</h6>
            <p>
              {currentRide.route?.fareDetails?.totalFare?.toFixed(2)}{" "}
              {currentRide.route?.fareDetails?.currency}
            </p>
          </div>

          <div className="mb-3">
            <CustomButton
              title="Pay With Cash"
              className="mr-2"
              onClick={payWithCashHandler}
            />
            <CustomButton
              title="Pay With Wallet"
              onClick={payWithWalletHandler}
            />
          </div>
        </Modal.Body>{" "}
        {/* <Modal.Footer>
          <CustomButton
            title="Submit"
            className="btn btn-success"
            isLoading={isSubmitLoading}
            onClick={submitHandler}
          />
        </Modal.Footer> */}
      </div>
    </Modal>
  );
}

export default RideCompleteModal;
