import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { asyncGetRides } from "../../redux/slices/rideSlice";
import { Link } from "react-router-dom";
import moment from "moment/moment";
// import { formatRideTime } from "../../utils/formatTime";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

// const columns = [
//   {
//     displayName: "Passenger Name",
//     displayField: (e) => (
//       <>
//         {e.passenger?.firstName} {e.passenger?.lastName}
//       </>
//     ),
//     searchable: true,
//   },
//   // {
//   //   displayName: "Driver Name",
//   //   displayField: (e) => (
//   //     <>
//   //       {e.driver?.firstName} {e.driver?.lastName}
//   //     </>
//   //   ),
//   //   searchable: true,
//   // },
//   {
//     displayName: "Pickup",
//     displayField: (e) => (
//       <>
//         {e?.route?.pickup
//           ? e?.route?.pickup?.address?.formattedAddress
//           : `${e?.rideRequest?.pickup?.address?.formattedAddress} ( Est )`}
//       </>
//     ),
//     searchable: true,
//   },
//   {
//     displayName: "Dropoff",
//     displayField: (e) => (
//       <>
//         {e?.route?.dropoff
//           ? e?.route?.dropoff?.address?.formattedAddress
//           : `${e?.rideRequest?.dropoff?.address?.formattedAddress} ( Est )`}
//       </>
//     ),
//     searchable: true,
//   },
//   {
//     // key: "verified",
//     displayName: "Booking Time",
//     displayField: (e) => {
//       return <>{moment(e?.bookingTime).format("hh:mm a @ DD MMM, YYYY ")}</>;
//     },
//     searchable: true,
//   },
//   {
//     displayName: "Distance / Duration",
//     displayField: (e) => (
//       <>
//         {e?.route?.rideEnded
//           ? `${e?.route?.distanceFromPickupToDropoff} km / ${e?.route?.pickupToDropoffTimeInMinutes} mins`
//           : `${e?.rideRequest?.distance} / ${e?.rideRequest?.duration} ( Est )`}
//       </>
//     ),
//   },
//   {
//     displayName: "Fare",
//     displayField: (e) => (
//       <>
//         {e?.route?.fareDetails
//           ? e?.route?.fareDetails?.totalFare
//           : `${e?.rideRequest?.fareDetails?.currency} ${e?.rideRequest?.fareDetails?.totalFare} ( Est )`}
//       </>
//     ),
//   },

//   {
//     displayName: "Waiting Time",
//     displayField: (e) => (
//       <>
//         {e?.route?.rideStarted ? (
//           <>
//             {formatRideTime(
//               moment(e.rideActions[3].timestamp).diff(
//                 moment(e.rideActions[2].timestamp),
//                 "seconds"
//               )
//             )}
//           </>
//         ) : (
//           <>N/A</>
//         )}
//       </>
//     ),
//   },

//   {
//     displayName: "Ride Time",
//     displayField: (e) => (
//       <>
//         {e?.route?.rideEnded ? (
//           <>
//             {formatRideTime(
//               moment(e.rideActions[4].timestamp).diff(
//                 moment(e.rideActions[3].timestamp),
//                 "seconds"
//               )
//             )}
//           </>
//         ) : (
//           <>N/A</>
//         )}
//       </>
//     ),
//   },
//   {
//     key: "status",
//     displayName: "Status",
//   },
//   {
//     displayName: "Action",
//     key: "",
//     displayField: (e) => (
//       <div class="task_view">
//         <div class="dropdown">
//           <Link
//             class="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
//             type="link"
//             data-toggle="dropdown"
//             aria-haspopup="true"
//             aria-expanded="false"
//             to={``}
//           >
//             <i class="icon-options-vertical icons"></i>
//           </Link>
//           <div
//             class="dropdown-menu dropdown-menu-right dropdown-menu dropdown-menu-right"
//             aria-labelledby="dropdownMenuLink-20"
//           >
//             <Link to={`/rides/${e._id}`} class="dropdown-item">
//               <i class="fa fa-eye"> View</i>
//             </Link>
//             <button class="dropdown-item">
//               <i class="fa fa-edit"> Edit</i>
//             </button>
//             <a
//               class="dropdown-item delete-table-row"
//               data-id="20"
//               href="/clients"
//             >
//               <i class="fa fa-trash"> Delete</i>
//             </a>
//           </div>
//         </div>
//       </div>
//     ),
//     searchable: true,
//   },
// ];

const columns = [
  {
    displayName: "Passenger Name",
    displayField: (e) => (
      <>
        {e?.passenger ? (
          <>
            {e?.passenger?.firstName} {e?.passenger?.lastName}
          </>
        ) : (
          <>N/A</>
        )}{" "}
      </>
    ),
    searchable: true,
  },
  // {
  //   displayName: "Driver Name",
  //   displayField: (e) => (
  //     <>
  //       {e.driver?.firstName} {e.driver?.lastName}
  //     </>
  //   ),
  //   searchable: true,
  // },
  {
    displayName: "Pickup",
    displayField: (e) => (
      <>
        {e?.route?.pickup
          ? e?.route?.pickup?.address?.formattedAddress
          : `${e?.rideRequest?.pickup?.address?.formattedAddress} ( Est )`}
      </>
    ),
    searchable: true,
  },
  {
    displayName: "Dropoff",
    displayField: (e) => (
      <>
        {e?.route?.dropoff
          ? e?.route?.dropoff?.address?.formattedAddress
          : `${e?.rideRequest?.dropoff?.address?.formattedAddress} ( Est )`}
      </>
    ),
    searchable: true,
  },
  {
    // key: "verified",
    displayName: "Booking Time",
    displayField: (e) => {
      return <>{moment(e?.bookingTime).format("hh:mm a @ DD MMM, YYYY ")}</>;
    },
    searchable: true,
  },
  {
    displayName: "Distance / Duration",
    displayField: (e) => (
      <>
        {e?.route?.distance
          ? e?.route?.distance
          : `${e?.rideRequest?.distance} / ${e?.rideRequest?.duration} ( Est )`}
      </>
    ),
  },
  {
    displayName: "Fare",
    displayField: (e) => (
      <>
        {e?.route?.fareDetails
          ? e?.route?.fareDetails?.totalFare
          : `${e?.rideRequest?.fareDetails?.currency} ${e?.rideRequest?.fareDetails?.totalFare} ( Est )`}
      </>
    ),
  },

  {
    key: "status",
    displayName: "Status",
  },
  {
    displayName: "Action",
    key: "",
    displayField: (e) => (
      <form
        method="POST"
        action="https://meetmighty.com/mobile/mighty-taxi/rider/36"
        acceptCharset="UTF-8"
        data--submit="rider36"
      >
        <input name="_method" type="hidden" defaultValue="DELETE" />
        <input
          name="_token"
          type="hidden"
          defaultValue="nKXO2rpGnpIJanM4AITcm75gWgwnlaw7rTcv6PKI"
        />
        <div className="d-flex justify-content-start align-items-center">
          {/* <Link className="mr-2" to="" title="Update Rider">
            <i className="fas fa-edit text-primary" />
          </Link> */}
          <Link className="mr-2" to={`/rides/${e._id}`}>
            <i className="fas fa-eye text-secondary" />
          </Link>
          {/* <a
            className="mr-2 text-danger"
            href="javascript:void(0)"
            data--submit="rider36"
            data--confirmation="true"
            data-title="Delete Rider"
            title="Delete Rider"
            data-message="Are you sure you want to delete?"
          >
            <i className="fas fa-trash-alt" />
          </a> */}
        </div>
      </form>
    ),
    searchable: true,
  },
];
const RidesTable = () => {
  const auth = useSelector((state) => state.auth);
  const rides = useSelector((state) => state.rides);

  const { isLoading, list } = rides;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });

  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };

  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= users.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  useEffect(() => {
    dispatch(asyncGetRides());
  }, []);

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};
export default RidesTable;
