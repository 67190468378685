import React from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../CustomButton";
import { useSelector } from "react-redux";

function MobileMoneyDetailsModal({ show, closePopup }) {
  const settings = useSelector((state) => state.settings);

  const { data } = settings;

  const { mobileMoneySettings } = data;
  console.log(mobileMoneySettings, "moblie");

  return (
    <Modal show={show} centered>
      <div className="small-modal">
        <Modal.Header className="d-flex justify-content-between">
          <h5>Mobile Money Details</h5>
          {/* <h6 className="text-danger">{formattedTimeLeft}</h6> */}
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            {/* <p>
                <span className="fw-semibold">Note:</span> Please transfer the
                amount <span className="fw-semibold">USD {amount}</span> to
                <span className="fw-semibold">account # +923362481489</span> and
                call the agent{" "}
                <span className="fw-semibold">( Contact: +923362481489 )</span>{" "}
                to approve your topup request.
              </p> */}
            <p>
              Please transfer the desired amount in the account # mentioned
              below and call the agent.
            </p>
            <p>
              <span className="fw-semibold">Account Title : </span>{" "}
              {mobileMoneySettings ? mobileMoneySettings.accountTitle : " "}
            </p>
            <p>
              <span className="fw-semibold">Account #:</span>{" "}
              {mobileMoneySettings ? mobileMoneySettings.accountNumber : " "}
            </p>{" "}
            <p>
              <span className="fw-semibold">Agent Contact #:</span>{" "}
              {mobileMoneySettings ? mobileMoneySettings.agentContact : " "}
            </p>
            <p>
              <span className="fw-semibold">Service Name :</span>{" "}
              {mobileMoneySettings ? mobileMoneySettings.serviceName : " "}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            type="button"
            title="Close"
            className="btn btn-danger"
            // isLoading={isLoading}
            onClick={closePopup}
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default MobileMoneyDetailsModal;
