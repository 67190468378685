import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetRides = createAsyncThunk(
  "getRides",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/rides/driver");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetRideDetails = createAsyncThunk(
  "getRideDetails",
  async (id, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/rides/${id}`);

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetCurrentRide = createAsyncThunk(
  "getCurrentRide",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/v2/rides/getDriverCurrentRide");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      // toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateRide = createAsyncThunk(
  "createRides",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.post("/vehicles", obj);
      toast.success("Ride created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncUpdateRide = createAsyncThunk(
  "updateRide",
  async (
    { id, data },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/vehicles/${id}`, data);
      toast.success("Ride created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetChat = createAsyncThunk(
  "getChat",
  async (id, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/rides/${id}/messages`);
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

const rideSlice = createSlice({
  name: "rides",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
    currentRide: null,
    rideDetails: { ride: null },
    hasActiveRide: false,
    chatMessages: [],
  },
  extraReducers: (builder) => {
    // Cases for Get Rides

    builder.addCase(asyncGetRides.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRides.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRides.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for ride details
    builder.addCase(asyncGetRideDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRideDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rideDetails = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRideDetails.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for current ride
    builder.addCase(asyncGetCurrentRide.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetCurrentRide.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentRide = action.payload.ride ? action.payload.ride : null;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetCurrentRide.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Add Ride
    builder.addCase(asyncCreateRide.pending, (state, action) => {
      // console.log("Error", action);

      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateRide.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: "Ride Created Successfully!" };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreateRide.rejected, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update Ride
    builder.addCase(asyncUpdateRide.pending, (state, action) => {
      // console.log("Error", action);

      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateRide.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: "Ride Updated Successfully!" };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdateRide.rejected, (state, action) => {
      // console.log("Error", action);
      state.update = { isLoading: false, message: action.payload };
    });

    builder.addCase(asyncGetChat.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetChat.fulfilled, (state, action) => {
      state.isLoading = false;
      state.chatMessages = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetChat.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },

  reducers: {
    setRideDetails(state, action) {
      state.currentRide = action.payload;
      if (action.payload) {
        state.hasActiveRide = true;
      } else {
        state.hasActiveRide = false;
      }
    },
    updateRideDetails(state, action) {
      state.currentRide.route = action.payload.route;
      state.currentRide.paymentDetails = action.payload.paymentDetails;

      state.currentRide.rideActions = action.payload.rideActions;
      state.currentRide.status = action.payload.status;
    },
    updateCurrentRideActions(state, action) {
      state.currentRide.rideActions = action.payload;
    },
    cancelRide(state, action) {
      state.currentRide = null;
    },
    messageReceived(state, action) {
      state.chatMessages = [...state.chatMessages, action.payload];
    },
  },
});

export const {
  setRideDetails,
  updateRideDetails,
  updateCurrentRideActions,
  cancelRide,
  messageReceived,
} = rideSlice.actions;
export default rideSlice.reducer;
