class TokenService {
  getLocalRefreshToken() {
    const tokens = JSON.parse(localStorage.getItem("cabtifyDriverTokens"));
    return tokens?.refresh.token;
  }

  getLocalAccessToken() {
    const tokens = JSON.parse(localStorage.getItem("cabtifyDriverTokens"));
    return tokens?.access.token;
  }

  updateLocalAccessToken(token) {
    let tokens = JSON.parse(localStorage.getItem("cabtifyDriverTokens"));
    tokens.access = token;
    localStorage.setItem("cabtifyDriverTokens", JSON.stringify(tokens));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("cabtifyDriverTokens"));
  }
}

export default new TokenService();
