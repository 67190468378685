import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetPassengers = createAsyncThunk(
  "getPassengers",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/passengers");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreatePassenger = createAsyncThunk(
  "createPassengers",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.post("/passengers", obj);
      toast.success("Passenger updated Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncUpdatePassenger = createAsyncThunk(
  "updatePassenger",
  async (
    { id, data },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/passengers/${id}`, data);
      toast.success("Passenger created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const passengerSlice = createSlice({
  name: "passengers",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
  },
  extraReducers: (builder) => {
    // Cases for Get Passengers

    builder.addCase(asyncGetPassengers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetPassengers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetPassengers.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Add Passenger
    builder.addCase(asyncCreatePassenger.pending, (state, action) => {
      // console.log("Error", action);

      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreatePassenger.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = {
        isLoading: false,
        message: "Passenger Created Successfully!",
      };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreatePassenger.rejected, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update Passenger
    builder.addCase(asyncUpdatePassenger.pending, (state, action) => {
      // console.log("Error", action);

      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdatePassenger.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = {
        isLoading: false,
        message: "Passenger Updated Successfully!",
      };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdatePassenger.rejected, (state, action) => {
      // console.log("Error", action);
      state.update = { isLoading: false, message: action.payload };
    });
  },
  reducers: {
    // userLogout(state, action) {
    //   state.userInfo = null;
    // },
  },
});

export const {
  // userLogout
} = passengerSlice.actions;
export default passengerSlice.reducer;
