import React, { useState } from "react";
import PageSubTitle from "../../components/pageSubTitle";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PersonalDetailsForm = ({ personalData, setPersonalData }) => {
  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;
  // const [personalData, setPersonalData] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   phone: userInfo ? userInfo.phone : "",
  //   address: "",
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalData({            
      ...personalData,
      [name]: value,              
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission here, e.g., send data to the server
    console.log(personalData);
  };

  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [third])

  return (
    <div className="container mt-4">
      {/* <h2>Personal Details Form</h2> */}
      {/* <form onSubmit={handleSubmit}> */}
      <PageSubTitle pageTitle="Personal Details" />

      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="firstName" className="form-label">
            First Name
          </label>
          <input               
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={personalData.firstName}            
            onChange={handleChange}
            required                
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="lastName" className="form-label">
            Last Name
          </label>                   
          <input
            type="text"
            className="form-control"
            id="lastName"           
            name="lastName"
            value={personalData.lastName}
            onChange={handleChange}
            required                              
          />
        </div>

        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="phone" className="form-label">
            Phone
          </label>
          <input
            type="tel"
            className="form-control"          
            id="phone"
            name="phone"
            value={personalData.phone}
            onChange={handleChange}
            disabled={userInfo}                 
            required
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>          
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={personalData.email}           
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label htmlFor="email" className="form-label">
            Password
          </label>      
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={personalData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="address" className="form-label">
            Address
          </label>
          <textarea
            className="form-control"
            id="address"
            name="address"                                                                                                                             
            rows={3}
            value={personalData.address}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      {/* <h5 className="text-center">Identity Card Details</h5> */}
      {/* <PageSubTitle pageTitle="Identity Card Details" />
      <div className="row my-4">
        <div className="col-12 col-md-8 mb-3">
          <label htmlFor="identityCardNumber" className="form-label">
            Card Number
          </label>
          <input
            type="tel"
            className="form-control"
            id="identityCardNumber"
            name="identityCardNumber"
            value={personalData._identityCardNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <label htmlFor="identityCardExpiry" className="form-label">
            Expiry
          </label>
          <input
            type="date"
            className="form-control"
            id="identityCardExpiry"
            name="identityCardExpiry"
            value={personalData._identityCardExpiry}
            onChange={handleChange}
            required
          />
        </div>
      </div> */}
      <PageSubTitle pageTitle="Driving License Details" />

      <div className="row my-4">
        <div className="col-12 col-md-8 mb-3">
          <label htmlFor="drivingLicenseNumber" className="form-label">
            License Number
          </label>
          <input               
            type="tel"
            className="form-control"
            id="drivingLicenseNumber"
            name="drivingLicenseNumber"
            value={personalData.drivingLicenseNumber}
            onChange={handleChange}                      
            required
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <label htmlFor="drivingLicenseExpiry" className="form-label">
            Expiry
          </label>     
          <input      
            type="date"
            className="form-control"
            id="drivingLicenseExpiry"
            name="drivingLicenseExpiry"               
            value={personalData.drivingLicenseExpiry}
            onChange={handleChange}
          />          
        </div>
      </div>
      {/* <button type="submit" className="btn btn-primary">
          Submit
        </button> */}
      {/* </form> */}
    </div>
  );
};

export default PersonalDetailsForm;
