import React from "react";

function Footer() {
  return (
    <footer className="mm-footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <ul className="list-inline mb-0"></ul>
          </div>
          <div className="col-lg-6 text-right">
            <span className="mr-1">
              Copyright 2023 ©{" "}
              <a href="#" className="">
                Cabtify
              </a>
              All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
