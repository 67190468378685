import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetDrivers = createAsyncThunk(
  "getDrivers",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/drivers");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateDriver = createAsyncThunk(
  "createDrivers",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.post("/drivers", obj);
      toast.success("Driver updated Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncUpdateDriver = createAsyncThunk(
  "updateDriver",
  async (
    { id, data },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/drivers/${id}`, data);
      toast.success("Driver created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const driverSlice = createSlice({
  name: "drivers",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
    isOnline: false,
  },
  extraReducers: (builder) => {
    // Cases for Get Drivers

    builder.addCase(asyncGetDrivers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetDrivers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetDrivers.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Add Driver
    builder.addCase(asyncCreateDriver.pending, (state, action) => {
      // console.log("Error", action);

      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateDriver.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: "Driver Created Successfully!" };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreateDriver.rejected, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update Driver
    builder.addCase(asyncUpdateDriver.pending, (state, action) => {
      // console.log("Error", action);

      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateDriver.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: "Driver Updated Successfully!" };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdateDriver.rejected, (state, action) => {
      // console.log("Error", action);
      state.update = { isLoading: false, message: action.payload };
    });
  },
  reducers: {
    // userLogout(state, action) {
    //   state.userInfo = null;
    // },
  },
});

export const {} = driverSlice.actions;
export default driverSlice.reducer;
