import React from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../CustomButton";
import { useDispatch } from "react-redux";
import {
  asyncsendClearSattlementRequestByDriver,
  asyncsendClearSattlementRequestByDriverViaWallet,
} from "../../redux/slices/SattlementSlice";

function SattlementPaymentModal({
  show,
  closePopup,
  payViaMobileMoneyHandler,
  payViaWalletHandler,
}) {
  const dispatch = useDispatch();

  const sendClearSattlementRequestHandler = async (e) => {
    e.preventDefault();
    dispatch(
      asyncsendClearSattlementRequestByDriver({
        callback: () => payViaMobileMoneyHandler(),
      })
    );
  };

  const sendClearSattlementRequestViaWalletHandler = async (e) => {
    e.preventDefault();
    dispatch(
      asyncsendClearSattlementRequestByDriverViaWallet({
        callback: () => payViaWalletHandler(),
      })
    );
  };

  return (
    <Modal show={show} centered>
      <div className="small-modal">
        <Modal.Header className="d-flex justify-content-between">
          <h5>Sattlement Amount Payment</h5>
          {/* <h6 className="text-danger">{formattedTimeLeft}</h6 > */}
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <p>Please select the payment method.</p>
            {/* <p>       
              <span className="fw-semibold">Account Title : </span>{" "}
              {mobileMoneySettings ? mobileMoneySettings.accountTitle : " "}
            </p>
            <p>
              <span className="fw-semibold">Account #:</span>{" "}
              {mobileMoneySettings ? mobileMoneySettings.accountNumber : " "}
            </p>{" "}
            <p>
              <span className="fw-semibold">Agent Contact #:</span>{" "}
              {mobileMoneySettings ? mobileMoneySettings.agentContact : " "}
            </p>
            <p>
              <span className="fw-semibold">Service Name :</span>{" "}
              {mobileMoneySettings ? mobileMoneySettings.serviceName : " "}
            </p> */}

            <CustomButton
              title="Pay via Wallet"
              className="btn btn-primary mr-2"
              // isLoading={false}
              onClick={sendClearSattlementRequestViaWalletHandler}
            />
            <CustomButton
              title="Pay via Mobile Money"
              className="btn btn-primary"
              // isLoading={isLoading}
              onClick={sendClearSattlementRequestHandler}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            type="button"
            title="Close"
            className="btn btn-danger"
            // isLoading={isLoading}
            onClick={closePopup}
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default SattlementPaymentModal;
