import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetVehicleCategories = createAsyncThunk(
  "getVehicleCategories",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/vehicleCategories");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncCreateVehicleCategory = createAsyncThunk(
  "createVehicleCategories",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.post("/vehicleCategories", obj);
      toast.success("Vehicle category created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncUpdateVehicleCategory = createAsyncThunk(
  "updateVehicleCategory",
  async (
    { id, data },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(
        `/vehicleCategories/${id}`,
        data
      );
      toast.success("Vehicle category created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const vehicleCategorySlice = createSlice({
  name: "vehicleCategories",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
  },
  extraReducers: (builder) => {
    // Cases for Get Rides

    builder.addCase(asyncGetVehicleCategories.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetVehicleCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetVehicleCategories.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Add Ride
    builder.addCase(asyncCreateVehicleCategory.pending, (state, action) => {
      // console.log("Error", action);

      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateVehicleCategory.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = {
        isLoading: false,
        message: "Vehicle category created successfully!",
      };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreateVehicleCategory.rejected, (state, action) => {
      // console.log("Error", action);
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update Ride
    builder.addCase(asyncUpdateVehicleCategory.pending, (state, action) => {
      // console.log("Error", action);

      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateVehicleCategory.fulfilled, (state, action) => {
      // console.log("Error", action);
      state.add = {
        isLoading: false,
        message: "Vehicle category updated successfully!",
      };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdateVehicleCategory.rejected, (state, action) => {
      // console.log("Error", action);
      state.update = { isLoading: false, message: action.payload };
    });
  },
  reducers: {
    // userLogout(state, action) {
    //   state.userInfo = null;
    // },
  },
});

export const {
  // userLogout
} = vehicleCategorySlice.actions;
export default vehicleCategorySlice.reducer;
