import React from "react";
import { useState } from "react";
// import MobileMoneyDetailsModal from "../../components/modals/MobileMoneyDetailsModal";
import SattlementPaymentModal from "../../components/modals/SattlementPaymentModal";

import CustomButton from "../../components/CustomButton";
import MobileMoneyDetailsModal from "../../components/modals/MobileMoneyDetailsModal";

function UnclearSattlement({ data }) {
  const [showMobileMoneyPopup, setShowMobileMoneyPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);

  const closePaymentPopup = () => {
    setShowPaymentPopup(false);
  };

  const closeMobileMoneyPopup = () => {
    setShowMobileMoneyPopup(false);
  };

  const payViaWalletHandler = () => {
    closePaymentPopup();
    setShowMobileMoneyPopup(false);
  };

  const payViaMobileMoneyHandler = () => {
    closePaymentPopup();
    setShowMobileMoneyPopup(true);
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-block card-stretch card-height ">
          <div className="card-header d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title mb-0">
                Previous Sattlement Amount Due on you
              </h4>
            </div>
            {/* <Link to="add" className="float-right btn btn-sm btn-primary">
        <i className="fa fa-plus-circle" /> Add Passenger
      </Link>{" "} */}
          </div>
          {/* <div class="card-header">
      <h5 class="card-title">Settlement Amount Due Today</h5>
    </div> */}
          <div class="card-body">
            <p>
              <strong>Note:</strong> You are currently restricted from getting
              rides as you have previous unclear dues, you will be able continue
              getting the rides once you clear your dues.
            </p>{" "}
            <div class="alert alert-info fsz-2">
              <strong>
                Due Date:<span> </span>{" "}
              </strong>{" "}
              {data[data.length - 1]?.date} ( You are late )
            </div>
            <div class="alert alert-success fsz-2">
              <strong>Due Amount: </strong> $
              {data?.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.totalCommissionDue,
                0
              )}{" "}
            </div>
            <p>
              This is the settlement amount due on you. Please make the payment
              to the Cabtify then you will be able to receive the rides.
            </p>{" "}
            <CustomButton
              // type="submit"
              title="Clear pending dues"
              class="btn btn-success mr-2"
              onClick={() => setShowPaymentPopup(true)}
            />{" "}
            <CustomButton
              // type="submit"
              title="Mobile Money Details"
              class="btn btn-primary"
              onClick={() => setShowMobileMoneyPopup(true)}
            />
          </div>
        </div>
      </div>
      <SattlementPaymentModal
        show={showPaymentPopup}
        closePopup={closePaymentPopup}
        payViaMobileMoneyHandler={payViaMobileMoneyHandler}
        payViaWalletHandler={payViaWalletHandler}
      />
      <MobileMoneyDetailsModal
        show={showMobileMoneyPopup}
        closePopup={closeMobileMoneyPopup}
      />
    </>
  );
}

export default UnclearSattlement;
