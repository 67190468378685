import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
// import { asyncGetDrivers } from "../../redux/slices/driverSlice";
import { Link } from "react-router-dom";
import { asyncGetRideRequests } from "../../redux/slices/rideRequestSlice";
import moment from "moment";
import CurrencySymbol from "../../components/CurrencySymbol";
// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const RideRequestsTable = () => {
  const auth = useSelector((state) => state.auth);       
  const rideRequests = useSelector((state) => state.rideRequests);

  const { isLoading, list } = rideRequests;
  // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);       
  // const openMoreVertDropDown = () => {
  //   setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  // };

  // const [modal, setModal] = useState({ open: false, data: null });

  // const handleModal = (data) => {
  //   setModal({ open: !modal.open, data: data });
  // };
  const dispatch = useDispatch();
  // const [currentPageNumber, setCurrentPageNumber] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= users.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  useEffect(() => {                
    dispatch(asyncGetRideRequests());
  }, [dispatch]);
        
  const columns = [
    {
      // key: "firstName",
      displayName: "Passenger Name",
      displayField: (e) => {
        return (
          <>
            {e.passenger ? (
              <>
                {e.passenger?.firstName} {e.passenger?.lastName}
              </>
            ) : (
              <>N/A</>
            )}
          </>
        );
      },
      searchable: true,
    },
    {
      // key: "phone",
      displayName: "Est. Pickup",
      displayField: (e) => {
        return <>{e?.pickup?.address?.formattedAddress}</>;
      },
      searchable: true,
    },
    {
      // key: "phone",
      displayName: "Est. Dropoff",
      displayField: (e) => {
        return <>{e?.dropoff?.address?.formattedAddress}</>;
      },
      searchable: true,
    },
    {
      // key: "phone",
      displayName: "Est. Distance/Duration",
      displayField: (e) => {
        return (
          <>
            {e.distance} / {e.duration}
          </>
        );
      },
      searchable: true,
    },
    {
      displayName: "Est. Fare",
      displayField: (e) => {
        return (
          <>
            <CurrencySymbol
              currencyCode={
                e?.fareDetails?.currency
                  ? e?.fareDetails?.currency
                  : auth?.userInfo?.country?.currency
              }
            />{" "}
            {e.fareDetails?.totalFare}
          </>
        );
      },
    },
    {
      displayName: "Booking Time",
      displayField: (e) => {
        return <>{moment(e.bookingTime).format("hh:mm a @ DD MMMM, YYYY")}</>;
      },
    },
    //   {
    //     // key: "verified",
    //     displayName: "Verified",
    //     displayField: (e) => {
    //       return <>{e.verified ? "Yes" : "No"}</>;
    //     },
    //     searchable: true,
    //   },

    {
      key: "status",
      displayName: "Status",
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => <Link to={`/ride-requests/${e._id}`}>View</Link>,
      searchable: true,
    },
  ];

  console.log("ride request table");
  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};
export default RideRequestsTable;
