import React from "react";
import DotsLoader from "./loaders/DotsLoader";

function CustomButton(props) {
  const { title, isLoading, icon, className } = props;
  return (
    <button
      disabled={isLoading}
      {...props}
      className={`btn btn-success ${className ? className : ""}`}
    >
      {isLoading ? (
        <DotsLoader />
      ) : (
        <>
          {icon} {title}
        </>
      )}
    </button>
  );
}

export default CustomButton;
